import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  addOnData: {},
  selectedData: {}
};

export const addOnSlice = createSlice({
  name: 'addOns',
  initialState,
  reducers: {
    setAddOnData: (state, action) => {
      const { addOnCode, compCode } = action.payload;
      const existingAddOns = state.addOnData?.[compCode] || [];

      const updatedAddOns = conflictAddOns(existingAddOns, addOnCode);

      if (updatedAddOns.includes(addOnCode)) {
        state.addOnData = {
          ...state.addOnData,
          [compCode]: updatedAddOns.filter((code) => code !== addOnCode)
        };
      } else {
        state.addOnData = {
          ...state.addOnData,
          [compCode]: [...updatedAddOns, addOnCode]
        };
      }
    },
    setSelectAll: (state, action) => {
      const { addOns, compCode } = action.payload;
      const updatedAddOns = conflictAddOns(addOns);
      state.addOnData = {
        ...state.addOnData,
        [compCode]: updatedAddOns
      };
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    resetVehicleState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const {
  resetVehicleState,
  setAddOnData,
  setSelectAll,
  setSelectedData
} = addOnSlice.actions;

export default addOnSlice.reducer;

const conflictAddOns = (addOns, addOnCode = 'ALLD') => {
  const conflictingAddOns = {
    ALLD: 'AND',
    AND: 'ALLD'
  };

  const conflictingAddOn = conflictingAddOns[addOnCode];
  return addOns.filter((code) => code !== conflictingAddOn);
};
