/** @jsxImportSource theme-ui */
import React from 'react';

import PolicyTypeModalLogic from './Logic/PolicyTypeModal.logic';
import { style } from './PolicyTypeModal.style';
import { Loading, RadioBoxContainer } from 'components';
import { ButtonUi } from 'components/UIComponent';
import { Translate } from 'hooks/Translation';

function PolicyTypeModal() {
  const { changeEvent, coverType, data, isLoading } = PolicyTypeModalLogic();
  const { t } = Translate();

  return (
    <div style={style.container}>
      <div sx={style.title}>{t('p_type')}</div>
      {data.map((item, index) => (
        <div key={index} style={style.coverContainer(coverType === item.value)}>
          {(index !== 1 && isLoading?.[index]?.sumInsured) ||
          isLoading?.[index]?.reducedSumInsured ? (
            <Loading color={'blue'} />
          ) : (
            <RadioBoxContainer
              data={item}
              title={item.title}
              info={item.info}
              pop={item.pop}
              index={index}
              isTitleBold={true}
              handleChangeEvent={() => changeEvent(index, item.value)}
              id={item.value}
              defaultChecked={coverType === item.value}
            />
          )}
        </div>
      ))}
      <ButtonUi label={'confirm'} />
    </div>
  );
}

export default PolicyTypeModal;
