import React from 'react';
import { Outlet } from 'react-router-dom';

import { Stepper } from 'components';
import { useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import useSteps from 'hooks/Steps';
import { Translate } from 'hooks/Translation';

const StepperLayout = () => {
  useSteps();
  const { steps } = useAppSelector((state) => state.base);
  const { t } = Translate();
  const { movePrev } = useStepper();

  return (
    <>
      <div className="mt-4 subBlueTitle cursor-pointer " onClick={movePrev}>
        {t('bck_button')}
      </div>
      <div className="quotation-wrapper-stepper">
        <Stepper activeStep={steps} />
      </div>
      <Outlet />
    </>
  );
};

export default StepperLayout;
