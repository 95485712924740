import React from 'react';

import './UserVerification.scss';
import UserVerificationLogic from './Logic/UserVerification.logic';
import { Tooltip } from 'components';
import { MainContainer } from 'components/UIComponent';
import { Translate } from 'hooks/Translation';
import { formatDateRange } from 'utils/helper';

export default function UserVerification() {
  const { t } = Translate();
  const {
    back,
    closeModal,
    confirmVariation,
    isLoading,
    modalProps,
    next,
    vix
  } = UserVerificationLogic();

  return (
    <MainContainer
      isLoading={isLoading}
      modalProps={modalProps}
      handleClose={closeModal}
    >
      <div className="user-verification p-sm-0 mt-4 mt-md-4 ">
        <div className="policy-box p-4 p-sm-4 px-sm-5">
          <div className="policy-information">
            <p className="blue-title medium mb-1">{t('policy_info')}</p>
            <div className="">
              <div className="percentage-box col-12 col-md-9 p-2 mt-2">
                <div className="tip d-flex">
                  {t('ncd_info_1')} {vix?.ncdPerc + '%'} {t('ncd_info_2')}{' '}
                  <div>
                    <Tooltip msg={t('info_pop')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  mt-2">
              <div className="mt-2">{t('policy_duration')}</div>
              <div>
                <p className="bold mb-1">{formatDateRange(vix?.expiryDate)}</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="blue-title col-12 mt-2 mb-2 medium">
              {t('vehicle_info')}
            </div>
            <div className="info-1 col-12 col-md-6">
              <div className="mb-2">
                <div>{t('vehicle_reg')}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {vix?.vehRegNo ? vix?.vehRegNo : '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div>{t('vehicle_make')}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {Object.keys(vix?.arrResExtraParam || {}).length !== 0
                        ? vix?.arrResExtraParam?.item[3]?.value
                        : '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div>{t('vehicle_var')}</div>
                <div className="dropdown">
                  <div>
                    {vix?.variation ? (
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={confirmVariation}
                      >
                        {vix?.variation.map((data, i) => (
                          <option value={i} key={i}>
                            {data.desc}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div>No data found</div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div>{t('engine_num')}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {vix?.engineNo ? vix?.engineNo : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-2 col-12 col-md-4 mt-3 mt-md-0">
              <div className="mb-2">
                <div>{t('chasis_num')}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {vix?.chassisNo ? vix?.chassisNo : '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div>{t('cubic_cap')}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {vix?.vehCapaCity ? vix?.vehCapaCity : '-'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div>{t('prod_year')}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {vix?.makeYear ? vix?.makeYear : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer text-center mt-5">
          <p>{t('your_vehicle')}</p>
          <div>
            <button className="no-button hover" data-event="no" onClick={back}>
              {t('no')}
            </button>
            <button
              className={
                vix?.variation ? 'yes-button hover' : 'disabled-button'
              }
              data-event="yes"
              onClick={next}
              disabled={!vix?.variation}
            >
              {t('yes')}
            </button>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
