import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';

import './Loader.Modal.scss';
import LoaderModalLogic from './Logic/LoaderModal.logic';
import { Moped, Sedan } from 'assets/images';

function LoaderModal({ isLoading }) {
  const { countOfProgess, imageLeftPosition, t } = LoaderModalLogic();

  return (
    <>
      <Modal show={true} className="loader-modal-container">
        <div className="image-wrapper" style={{ left: imageLeftPosition }}>
          <img src={Sedan} alt="sedan" />
          {/* {isCar ? (
          ) : (
            <img src={Moped} alt="moped" />
          )} */}
        </div>
        <div className="progress-container">
          <ProgressBar now={countOfProgess} variant="primary" />
          <br />
          <h5 className="text">{t('loading_msg')}</h5>
        </div>
      </Modal>
    </>
  );
}

export default LoaderModal;
