import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScroll = () => {
  const location = useLocation();

  const scrollToTop = () => {
    if (typeof window === 'undefined') return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToElement = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  };

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return {
    scrollToTop,
    scrollToElement
  };
};

export default useScroll;
