import React from 'react';

import { logoArray } from 'constants/label';

const LogoInsurer = ({ companyCode, showLogo = true, showName = false }) => {
  const logoInfo = logoArray[companyCode];

  if (!logoInfo) {
    return <p>Company not found</p>;
  }

  return (
    <div className={companyCode === '40' ? 'tune' : ''}>
      {showLogo && (
        <img src={logoInfo.logo} alt={logoInfo.name} className="logo-image" />
      )}
      {showName && <p>{logoInfo.name}</p>}
    </div>
  );
};

export default LogoInsurer;
