import React from 'react';

import { ErrorMessage } from '@hookform/error-message';

const InputRadioCheck = ({
  errorMessage,
  errors,
  errorStatus,
  fieldBgColor,
  fieldIconStatus,
  name,
  onClick,
  options,
  register,
  rules,
  style,
  t,
  title
}) => {
  return (
    <div className="col-lg-4 col-md-6 form-element-container" style={style}>
      <div className="gender-inner-container">
        <p className="gender-title">{title}</p>
        <div
          className="gender-container d-flex"
          style={{
            justifyContent: 'space-start',
            paddingBottom: '6px',
            paddingLeft: '18px',
            gap: '16px'
          }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={`radio-item ${option.active ? 'active' : ''}`}
              style={{ display: 'flex', gap: '6px' }}
            >
              <input
                onClick={() => onClick(option.value)}
                className={`form-check-input ${
                  errorStatus ? 'error' : fieldBgColor
                }`}
                type="radio"
                value={t(option.value)}
                name={name}
                id={`${name}-${option.value}`}
                {...register(name, rules?.[name])}
              />
              <label
                className="form-check-label"
                htmlFor={`${name}-${option.value}`}
              >
                {t(option.label)}
              </label>
            </div>
          ))}
          {fieldIconStatus && (
            <img className="icon" src={`${fieldIconStatus}`} alt="icon-error" />
          )}
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={() => {
          return <span className="error-label">{errorMessage}</span>;
        }}
      />
    </div>
  );
};

export default InputRadioCheck;
