export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto 32px'
  },
  iconContainer: {},
  icon: (bgColor) => ({
    backgroundColor: bgColor,
    borderRadius: '30px',
    padding: '20px'
  }),
  bodyContainer: {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  title: {
    textAlign: 'center',
    fontFamily: 'medium',
    fontSize: '24px',
    lineHeight: '29px'
  },
  message: {
    fontSize: '16px'
  },
  footNote: {
    fontFamily: 'medium',
    fontSize: '24px',
    color: '#5698FC'
  }
};
