import { useEffect, useState } from 'react';

import { benefitsLabel } from 'constants/label';
import { Translate } from 'hooks/Translation';

const PlanBoxLogic = (id, plans, benefits) => {
  const { t } = Translate();
  const [expandedPlans, setExpandedPlans] = useState({});
  const [benefitsData, setBenefitsData] = useState([]);
  const isExpanded = expandedPlans[id] || false;

  useEffect(() => {
    const updatedBenefitsData = benefitsLabel[benefits].slice(
      0,
      isExpanded ? benefitsLabel[benefits].length : 5
    );
    setBenefitsData(updatedBenefitsData);
  }, [isExpanded]);

  const toggleShowHide = (index) => {
    setExpandedPlans((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const noArrow = benefitsLabel[benefits].length <= 5;

  return {
    t,
    toggleShowHide,
    isExpanded,
    isReadMore: plans > 2,
    benefitsData,
    noArrow
  };
};

export default PlanBoxLogic;
