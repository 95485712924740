export const style = {
  title: {
    fontFamily: 'bold'
  },
  label: {
    fontFamily: 'medium'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    gap: '12px'
  },
  radioCheck: {
    display: 'flex',
    gap: '12px'
  },
  sortCard: (active) => ({
    display: 'flex',
    border: active ? '2px solid #5698FC' : '1px solid #DBDBDB',
    backgroundColor: active ? '#E7F6FF' : 'white',
    borderRadius: '5px',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px',
    gap: '12px'
  })
};
