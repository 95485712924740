export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  title: {
    fontFamily: 'bold'
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  coverField: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    border: '2px solid',
    borderColor: '#5698FC',
    borderRadius: '5px',
    gap: '10px'
  },
  fieldContainer: {
    padding: '0 12px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  inputLabel: {
    fontFamily: 'light',
    fontSize: '12px'
  }
};
