export const cityStatePostalCodes = {
  Johor: {
    'Ayer Baloi': ['82100'],
    'Ayer Tawar 2': ['81920'],
    Ayer_Hitam_J: ['86100'],
    'Bandar Penawar': ['81930'],
    'Bandar Tenggara': ['81440'],
    'Batu Anam': ['85100'],
    'Batu Pahat': ['83000'],
    Bekok: ['86500'],
    Benut: ['82200'],
    'Bukit Gambir': ['84800'],
    'Bukit Pasir': ['84300'],
    Chaah: ['85400'],
    Endau: ['86900'],
    'Gelang Patah': ['81550'],
    Gerisek: ['84700'],
    'Gugusan Taib Andak': ['81450'],
    Jementah: ['85200'],
    'Johor Bahru': [
      '80000',
      '80050',
      '80100',
      '80150',
      '80200',
      '80250',
      '80300',
      '80350',
      '80400',
      '80500',
      '80506',
      '80508',
      '80516',
      '80519',
      '80534',
      '80536',
      '80542',
      '80546',
      '80558',
      '80560',
      '80564',
      '80568',
      '80578',
      '80584',
      '80586',
      '80590',
      '80592',
      '80594',
      '80596',
      '80600',
      '80604',
      '80608',
      '80620',
      '80622',
      '80628',
      '80644',
      '80648',
      '80662',
      '80664',
      '80668',
      '80670',
      '80672',
      '80673',
      '80676',
      '80700',
      '80710',
      '80720',
      '80730',
      '80900',
      '80902',
      '80904',
      '80906',
      '80908',
      '80988',
      '80990',
      '81100',
      '81200',
      '81300',
      '81310'
    ],
    Kahang: ['86700'],
    Kluang: ['86000'],
    'Kota Tinggi': ['81900'],
    Kukup: ['82300'],
    Kulai: ['81000'],
    Labis: ['85300'],
    'Layang-Layang': ['81850'],
    Masai: ['81750'],
    Mersing: ['86800', '86810'],
    Muar: ['84000', '84200'],
    Nusajaya: [
      '79000',
      '79050',
      '79100',
      '79150',
      '79200',
      '79250',
      '79502',
      '79503',
      '79504',
      '79505',
      '79511',
      '79513',
      '79514',
      '79517',
      '79518',
      '79520',
      '79521',
      '79523',
      '79532',
      '79538',
      '79540',
      '79546',
      '79548',
      '79550',
      '79552',
      '79555',
      '79570',
      '79575',
      '79576',
      '79592',
      '79601',
      '79603',
      '79605',
      '79606',
      '79612',
      '79626',
      '79630',
      '79632',
      '79646',
      '79658',
      '79660',
      '79680',
      '79681',
      '79683'
    ],
    Pagoh: ['84600'],
    Paloh: ['86600'],
    Panchor: ['84500'],
    'Parit Jawa': ['84150'],
    'Parit Raja': ['86400'],
    'Parit Sulong': ['83500'],
    'Pasir Gudang': ['81700'],
    'Pekan Nenas': ['81500'],
    Pengerang: ['81600', '81620'],
    Pontian: ['82000'],
    Rengam: ['86300'],
    Rengit: ['83100'],
    Segamat: ['85000', '85030'],
    Semerah: ['83600'],
    Senai: ['81400'],
    Senggarang: ['83200'],
    'Seri Gading': ['83300'],
    'Seri Medan': ['83400'],
    'Simpang Rengam': ['86200'],
    'Sungai Mati': ['84400'],
    Tangkak: ['84900'],
    'Ulu Tiram': ['81800'],
    'Yong Peng': ['83700']
  },
  Kedah: {
    'Alor Setar': [
      '05000',
      '05050',
      '05100',
      '05150',
      '05200',
      '05250',
      '05300',
      '05350',
      '05400',
      '05460',
      '05500',
      '05502',
      '05503',
      '05504',
      '05505',
      '05506',
      '05508',
      '05512',
      '05514',
      '05516',
      '05517',
      '05518',
      '05520',
      '05532',
      '05534',
      '05536',
      '05538',
      '05550',
      '05551',
      '05552',
      '05556',
      '05558',
      '05560',
      '05564',
      '05576',
      '05578',
      '05580',
      '05582',
      '05586',
      '05590',
      '05592',
      '05594',
      '05600',
      '05604',
      '05610',
      '05612',
      '05614',
      '05620',
      '05621',
      '05622',
      '05626',
      '05628',
      '05630',
      '05632',
      '05644',
      '05660',
      '05661',
      '05664',
      '05670',
      '05672',
      '05673',
      '05674',
      '05675',
      '05676',
      '05680',
      '05690',
      '05696',
      '05700',
      '05710',
      '05720',
      '05990',
      '06250',
      '06509',
      '06550',
      '06570',
      '06660'
    ],
    Ayer_Hitam_K: ['06150'],
    Baling: ['09100'],
    Banda_Baharu_K: ['14290', '14390'],
    Bedong: ['08100', '08110'],
    'Bukit Kayu Hitam': ['06050'],
    Changloon: ['06010'],
    Gurun: ['08300', '08330', '08800'],
    Jeniang: ['08320', '08700'],
    Jitra: ['06000', '06007', '06009'],
    Karangan: ['09700'],
    Kepala_Batas_K: ['06200', '06207', '06209'],
    Kodiang: ['06100'],
    'Kota Kuala Muda': ['08500', '08507', '08509'],
    'Kota Sarang Semut': ['06800'],
    'Kuala Kedah': ['06600'],
    'Kuala Ketil': ['09300', '09310'],
    'Kuala Nerang': ['06300'],
    'Kuala Pegang': ['09110'],
    Kulim: ['09000', '09007', '09009', '09010', '09020'],
    Kupang: ['09200'],
    Langgar: ['06500', '06507'],
    Langkawi: ['07000', '07007', '07009'],
    Lunas: ['09600'],
    Merbok: ['08400', '08407', '08409'],
    'Padang Serai': ['09400', '09410'],
    Pendang: ['06700', '06707', '06709', '06710', '06720', '06750'],
    'Pokok Sena': ['06350', '06400'],
    Serdang_K: ['09800', '09810'],
    Sik: ['08200', '08210', '08340'],
    'Simpang Empat': ['06650'],
    'Sungai Petani': ['08000', '08007', '08009', '08010', '08600'],
    Yan: ['06900', '06910']
  },
  Kelantan: {
    'Ayer Lanas': ['17700'],
    Bachok: [
      '16020',
      '16030',
      '16050',
      '16060',
      '16070',
      '16090',
      '16300',
      '16310',
      '16320'
    ],
    'Cherang Ruku': ['16700'],
    Dabong: ['18200'],
    'Gua Musang': ['18300'],
    Jeli: ['17600'],
    'Kem Desa Pahlawan': ['16500'],
    Ketereh: ['16450'],
    'Kota Bharu': [
      '15000',
      '15050',
      '15100',
      '15150',
      '15159',
      '15200',
      '15300',
      '15350',
      '15400',
      '15500',
      '15502',
      '15503',
      '15504',
      '15505',
      '15506',
      '15508',
      '15512',
      '15514',
      '15516',
      '15517',
      '15518',
      '15519',
      '15520',
      '15524',
      '15529',
      '15532',
      '15534',
      '15536',
      '15538',
      '15540',
      '15546',
      '15548',
      '15550',
      '15551',
      '15556',
      '15558',
      '15560',
      '15564',
      '15570',
      '15572',
      '15576',
      '15578',
      '15582',
      '15586',
      '15590',
      '15592',
      '15594',
      '15596',
      '15600',
      '15604',
      '15608',
      '15609',
      '15612',
      '15614',
      '15616',
      '15622',
      '15623',
      '15626',
      '15628',
      '15630',
      '15632',
      '15634',
      '15644',
      '15646',
      '15648',
      '15658',
      '15660',
      '15661',
      '15664',
      '15670',
      '15672',
      '15673',
      '15674',
      '15676',
      '15680',
      '15690',
      '15710',
      '15720',
      '15730',
      '15740',
      '15988',
      '15990',
      '16010',
      '16100',
      '16109',
      '16150'
    ],
    'Kuala Balah': ['17610'],
    'Kuala Krai': ['18000', '18050'],
    Machang: ['18500'],
    Melor: ['16400'],
    'Pasir Mas': [
      '17000',
      '17007',
      '17009',
      '17010',
      '17020',
      '17030',
      '17040',
      '17050',
      '17060',
      '17070'
    ],
    'Pasir Puteh': ['16800'],
    'Pulai Chondong': ['16600'],
    Rantau_Panjang_K: ['17200'],
    Selising: ['16810'],
    'Tanah Merah': ['17500', '17507', '17509', '17510'],
    Temangan: ['18400'],
    Tumpat: ['16080', '16200', '16210'],
    'Wakaf Bharu': ['16040', '16250']
  },
  'Kuala Lumpur': {
    'Kuala Lumpur': [
      '50000',
      '50050',
      '50088',
      '50100',
      '50150',
      '50200',
      '50250',
      '50300',
      '50350',
      '50400',
      '50450',
      '50460',
      '50470',
      '50480',
      '50490',
      '50500',
      '50502',
      '50504',
      '50505',
      '50506',
      '50507',
      '50508',
      '50512',
      '50514',
      '50515',
      '50519',
      '50528',
      '50529',
      '50530',
      '50532',
      '50534',
      '50536',
      '50540',
      '50544',
      '50546',
      '50548',
      '50550',
      '50551',
      '50552',
      '50554',
      '50556',
      '50560',
      '50562',
      '50564',
      '50566',
      '50568',
      '50572',
      '50576',
      '50578',
      '50580',
      '50582',
      '50586',
      '50588',
      '50590',
      '50592',
      '50594',
      '50596',
      '50598',
      '50599',
      '50600',
      '50603',
      '50604',
      '50605',
      '50608',
      '50609',
      '50610',
      '50612',
      '50614',
      '50620',
      '50621',
      '50622',
      '50623',
      '50626',
      '50632',
      '50634',
      '50636',
      '50638',
      '50640',
      '50642',
      '50644',
      '50646',
      '50648',
      '50650',
      '50652',
      '50653',
      '50656',
      '50658',
      '50660',
      '50661',
      '50662',
      '50664',
      '50666',
      '50668',
      '50670',
      '50672',
      '50673',
      '50676',
      '50677',
      '50678',
      '50680',
      '50682',
      '50684',
      '50688',
      '50694',
      '50700',
      '50702',
      '50704',
      '50706',
      '50708',
      '50710',
      '50712',
      '50714',
      '50716',
      '50718',
      '50720',
      '50722',
      '50724',
      '50726',
      '50728',
      '50730',
      '50732',
      '50734',
      '50736',
      '50738',
      '50740',
      '50742',
      '50744',
      '50746',
      '50748',
      '50750',
      '50752',
      '50754',
      '50758',
      '50760',
      '50762',
      '50764',
      '50766',
      '50768',
      '50770',
      '50772',
      '50774',
      '50776',
      '50778',
      '50780',
      '50782',
      '50784',
      '50786',
      '50788',
      '50790',
      '50792',
      '50794',
      '50796',
      '50798',
      '50800',
      '50802',
      '50804',
      '50806',
      '50808',
      '50810',
      '50812',
      '50814',
      '50816',
      '50818',
      '50901',
      '50902',
      '50903',
      '50904',
      '50906',
      '50907',
      '50908',
      '50909',
      '50910',
      '50911',
      '50912',
      '50913',
      '50914',
      '50915',
      '50916',
      '50917',
      '50918',
      '50919',
      '50920',
      '50921',
      '50922',
      '50923',
      '50924',
      '50925',
      '50926',
      '50927',
      '50928',
      '50929',
      '50930',
      '50931',
      '50932',
      '50933',
      '50934',
      '50935',
      '50936',
      '50937',
      '50938',
      '50939',
      '50940',
      '50941',
      '50942',
      '50943',
      '50944',
      '50945',
      '50946',
      '50947',
      '50948',
      '50949',
      '50950',
      '50988',
      '50989',
      '50990',
      '51000',
      '51100',
      '51200',
      '51700',
      '51990',
      '52000',
      '52100',
      '52200',
      '53000',
      '53100',
      '53200',
      '53300',
      '53700',
      '53800',
      '53990',
      '54000',
      '54100',
      '54200',
      '55000',
      '55100',
      '55188',
      '55200',
      '55300',
      '55700',
      '55710',
      '55720',
      '55900',
      '55902',
      '55904',
      '55906',
      '55908',
      '55910',
      '55912',
      '55914',
      '55916',
      '55918',
      '55920',
      '55922',
      '55924',
      '55926',
      '55928',
      '55930',
      '55932',
      '55934',
      '55990',
      '56000',
      '56100',
      '57000',
      '57100',
      '57700',
      '57990',
      '58000',
      '58100',
      '58200',
      '58700',
      '58990',
      '59000',
      '59100',
      '59200',
      '59700',
      '59800',
      '59990',
      '60000'
    ]
  },
  Malacca: {
    'Alor Gajah': ['78000', '78009'],
    Asahan: ['77100', '77109'],
    'Ayer Keroh': ['75450'],
    Bemban: ['77200'],
    'Durian Tunggal': ['76100', '76109'],
    Jasin: ['77000', '77007', '77008', '77009'],
    'Kem Trendak': ['76200'],
    'Kuala Sungai Baru': ['78200'],
    'Lubok China': ['78100'],
    'Masjid Tanah': ['78300', '78307', '78309'],
    Melaka: [
      '75000',
      '75050',
      '75100',
      '75150',
      '75200',
      '75250',
      '75260',
      '75300',
      '75350',
      '75400',
      '75460',
      '75500',
      '75502',
      '75503',
      '75504',
      '75505',
      '75506',
      '75508',
      '75510',
      '75512',
      '75514',
      '75516',
      '75517',
      '75518',
      '75519',
      '75532',
      '75536',
      '75538',
      '75540',
      '75542',
      '75546',
      '75550',
      '75551',
      '75552',
      '75560',
      '75564',
      '75566',
      '75570',
      '75572',
      '75576',
      '75578',
      '75582',
      '75584',
      '75586',
      '75590',
      '75592',
      '75594',
      '75596',
      '75600',
      '75604',
      '75606',
      '75608',
      '75609',
      '75610',
      '75612',
      '75618',
      '75620',
      '75622',
      '75626',
      '75628',
      '75630',
      '75632',
      '75646',
      '75648',
      '75662',
      '75670',
      '75672',
      '75673',
      '75674',
      '75676',
      '75690',
      '75700',
      '75710',
      '75720',
      '75730',
      '75740',
      '75750',
      '75760',
      '75900',
      '75902',
      '75904',
      '75906',
      '75908',
      '75910',
      '75912',
      '75914',
      '75916',
      '75918',
      '75990',
      '76450'
    ],
    Merlimau: ['77300', '77309'],
    Selandar: ['77500'],
    'Sungai Rambai': ['77400', '77409'],
    'Sungai Udang': ['76300'],
    'Tanjong Kling': ['76400', '76409']
  },
  'Negeri Sembilan': {
    Bahau: ['72100', '72107', '72109'],
    'Bandar Enstek': ['71760'],
    'Bandar Seri Jempol': ['72120', '72127', '72129'],
    'Batu Kikir': ['72200', '72207', '72209'],
    Gemas: ['73400', '73409', '73420', '73480'],
    Gemencheh: ['73200', '73207', '73209', '73300', '73309'],
    Johol: ['73100', '73109'],
    Kota: ['71350', '71359'],
    'Kuala Klawang': ['71600', '71609', '71650', '71659'],
    'Kuala Pilah': ['72000', '72007', '72009', '72500', '72507', '72509'],
    Labu: ['71900', '71907', '71909'],
    Linggi: ['71150', '71159'],
    Mantin: ['71700', '71707', '71709', '71750', '71759'],
    Nilai: ['71800', '71807', '71809'],
    'Port Dickson': ['71000', '71007', '71009', '71010', '71960'],
    'Pusat  Bandar Palong': ['73430', '73440', '73450', '73460', '73470'],
    Rantau: ['71100', '71109', '71200', '71209'],
    Rembau: ['71300', '71309', '71400', '71409'],
    Rompin: ['73500', '73507', '73509'],
    Seremban: [
      '70000',
      '70100',
      '70200',
      '70300',
      '70400',
      '70450',
      '70500',
      '70502',
      '70503',
      '70504',
      '70505',
      '70506',
      '70508',
      '70512',
      '70516',
      '70517',
      '70518',
      '70532',
      '70534',
      '70536',
      '70540',
      '70546',
      '70548',
      '70550',
      '70551',
      '70558',
      '70560',
      '70564',
      '70570',
      '70572',
      '70576',
      '70578',
      '70582',
      '70586',
      '70590',
      '70592',
      '70594',
      '70596',
      '70600',
      '70604',
      '70606',
      '70608',
      '70609',
      '70610',
      '70620',
      '70626',
      '70628',
      '70632',
      '70634',
      '70644',
      '70646',
      '70648',
      '70658',
      '70664',
      '70670',
      '70672',
      '70673',
      '70674',
      '70676',
      '70690',
      '70700',
      '70710',
      '70720',
      '70730',
      '70740',
      '70750',
      '70990',
      '71450',
      '71459',
      '71770',
      '71950'
    ],
    'Si Rusa': ['71050', '71059', '71250', '71259'],
    'Simpang Durian': ['72400', '72409'],
    'Simpang Pertang': ['72300', '72307', '72309'],
    Tampin: ['73000', '73007', '73009'],
    'Tanjong Ipoh': ['71500', '71509', '71550', '71559']
  },
  Pahang: {
    Balok: ['26150', '26190'],
    'Bandar Bera': ['28200'],
    'Bandar Pusat Jengka': [
      '26400',
      '26410',
      '26420',
      '26430',
      '26440',
      '26450',
      '26460',
      '26485',
      '26490'
    ],
    'Bandar Tun Abdul Razak': ['26900'],
    Benta: ['27300', '27310'],
    Bentong: ['28700', '28707', '28709', '28730', '28740', '28750'],
    Brinchang: ['39100'],
    'Bukit Fraser': ['49000'],
    'Bukit Goh': ['26050', '26090'],
    'Bukit Kuin': ['26180'],
    Chenor: ['28100'],
    Chini: ['26690'],
    Damak: ['27030'],
    Dong: ['27400'],
    Gambang: [
      '26300',
      '26310',
      '26320',
      '26330',
      '26340',
      '26350',
      '26360',
      '26370'
    ],
    'Genting Highlands': ['69000'],
    Jerantut: [
      '27000',
      '27010',
      '27020',
      '27040',
      '27050',
      '27060',
      '27070',
      '27090',
      '27150'
    ],
    Karak: ['28600', '28610', '28620'],
    Kemayan: ['28340', '28380'],
    'Kuala Krau': ['28050'],
    'Kuala Lipis': ['27200', '27207', '27209', '27210'],
    'Kuala Rompin': ['26800', '26810', '26820'],
    Kuantan: [
      '25000',
      '25050',
      '25100',
      '25150',
      '25200',
      '25250',
      '25300',
      '25350',
      '25500',
      '25502',
      '25503',
      '25504',
      '25505',
      '25506',
      '25508',
      '25509',
      '25512',
      '25514',
      '25516',
      '25517',
      '25518',
      '25520',
      '25524',
      '25529',
      '25532',
      '25534',
      '25536',
      '25538',
      '25540',
      '25546',
      '25548',
      '25550',
      '25551',
      '25552',
      '25556',
      '25558',
      '25560',
      '25564',
      '25570',
      '25576',
      '25578',
      '25582',
      '25584',
      '25586',
      '25590',
      '25592',
      '25594',
      '25596',
      '25598',
      '25600',
      '25604',
      '25606',
      '25608',
      '25609',
      '25610',
      '25612',
      '25614',
      '25620',
      '25622',
      '25626',
      '25628',
      '25630',
      '25632',
      '25644',
      '25646',
      '25648',
      '25656',
      '25660',
      '25661',
      '25662',
      '25670',
      '25672',
      '25673',
      '25674',
      '25676',
      '25690',
      '25700',
      '25710',
      '25720',
      '25730',
      '25740',
      '25750',
      '25990',
      '26010',
      '26040',
      '26060',
      '26070',
      '26080',
      '26100',
      '26140',
      '26250'
    ],
    Lanchang: ['28500'],
    'Lurah Bilut': ['28800'],
    Maran: ['26500'],
    Mentakab: ['28400', '28407', '28409'],
    'Muadzam Shah': ['26700'],
    'Padang Tengku': ['27100'],
    Pekan: [
      '26600',
      '26607',
      '26609',
      '26610',
      '26620',
      '26630',
      '26640',
      '26650',
      '26660',
      '26680'
    ],
    Raub: ['27607', '27609', '27610', '27620', '27630', '27670'],
    Ringlet: ['39200'],
    Sega: ['27660'],
    'Sungai Koyan': ['27650'],
    'Sungai Lembing': ['26200'],
    'Tanah Rata': ['39000', '39007', '39009', '39010'],
    Temerloh: ['28000', '28007', '28009', '28010', '28020', '28030', '28040'],
    Triang: ['28300', '28310', '28320', '28330']
  },
  Penang: {
    'Ayer Itam': ['11500'],
    'Balik Pulau': ['11000', '11010', '11020'],
    'Batu Ferringhi': ['11100'],
    'Batu Maung': ['11960'],
    'Bayan Lepas': ['11900', '11910', '11920', '11950'],
    'Bukit Mertajam': ['14000', '14007', '14009', '14020'],
    Butterworth: [
      '12000',
      '12100',
      '12200',
      '12300',
      '12700',
      '12710',
      '12720',
      '12990',
      '13000',
      '13009',
      '13020',
      '13050',
      '13400',
      '13409',
      '13800'
    ],
    Gelugor: ['11700'],
    Jelutong: ['11600', '11609'],
    Kepala_Batas_P: ['13200', '13210', '13220'],
    'Kubang Semang': ['14400'],
    'Nibong Tebal': ['14300', '14310', '14320'],
    Penaga: ['13100', '13110'],
    'Penang Hill': ['11300'],
    Perai: ['13600', '13700'],
    'Permatang Pauh': ['13500'],
    'Pulau Pinang': [
      '10000',
      '10050',
      '10100',
      '10150',
      '10200',
      '10250',
      '10300',
      '10350',
      '10400',
      '10450',
      '10460',
      '10470',
      '10500',
      '10502',
      '10503',
      '10504',
      '10505',
      '10506',
      '10508',
      '10512',
      '10514',
      '10516',
      '10518',
      '10524',
      '10534',
      '10538',
      '10540',
      '10542',
      '10546',
      '10550',
      '10551',
      '10552',
      '10558',
      '10560',
      '10564',
      '10566',
      '10570',
      '10576',
      '10578',
      '10582',
      '10590',
      '10592',
      '10593',
      '10594',
      '10596',
      '10600',
      '10604',
      '10609',
      '10610',
      '10612',
      '10620',
      '10622',
      '10626',
      '10628',
      '10634',
      '10646',
      '10648',
      '10660',
      '10661',
      '10662',
      '10670',
      '10672',
      '10673',
      '10674',
      '10676',
      '10690',
      '10710',
      '10720',
      '10730',
      '10740',
      '10750',
      '10760',
      '10770',
      '10780',
      '10790',
      '10800',
      '10810',
      '10820',
      '10830',
      '10840',
      '10850',
      '10910',
      '10920',
      '10990',
      '11050',
      '11060',
      '11400',
      '11409'
    ],
    Simpang_Ampat_PN: ['14100', '14101', '14110', '14120'],
    'Sungai Jawi': ['14200'],
    'Tanjong Bungah': ['11200'],
    'Tasek Gelugor': ['13300', '13310'],
    'USM Pulau Pinang': ['11800']
  },
  Perak: {
    'Ayer Tawar': ['32400'],
    'Bagan Datoh': ['36100'],
    'Bagan Serai': ['34300', '34310'],
    Banda_Baharu_P: ['34950'],
    'Bandar Seri Iskandar': ['32610'],
    'Batu Gajah': ['31000', '31007', '31009'],
    'Batu Kurau': ['34500', '34510', '34520'],
    'Behrang Stesen': ['35950'],
    Bidor: ['35500'],
    Bota: ['32600'],
    Brua: ['32700'],
    'Changkat Jering': ['34850'],
    'Changkat Keruing': ['32500'],
    Chemor: ['31200'],
    Chenderiang: ['35300'],
    'Chenderong Balai': ['36600'],
    Chikus: ['36750'],
    Enggor: ['33600'],
    Gerik: ['33300', '33310', '33320'],
    Gopeng: ['31600', '31610'],
    'Hutan Melintang': ['36400'],
    Intan: ['33200'],
    Ipoh: [
      '30000',
      '30010',
      '30020',
      '30100',
      '30200',
      '30250',
      '30300',
      '30350',
      '30450',
      '30500',
      '30502',
      '30503',
      '30504',
      '30505',
      '30506',
      '30508',
      '30510',
      '30512',
      '30516',
      '30517',
      '30518',
      '30519',
      '30520',
      '30524',
      '30532',
      '30534',
      '30536',
      '30540',
      '30542',
      '30546',
      '30548',
      '30550',
      '30551',
      '30552',
      '30554',
      '30556',
      '30560',
      '30564',
      '30570',
      '30576',
      '30580',
      '30582',
      '30586',
      '30590',
      '30592',
      '30594',
      '30596',
      '30600',
      '30604',
      '30606',
      '30609',
      '30610',
      '30612',
      '30614',
      '30620',
      '30621',
      '30622',
      '30626',
      '30628',
      '30630',
      '30632',
      '30634',
      '30644',
      '30646',
      '30648',
      '30656',
      '30658',
      '30660',
      '30661',
      '30662',
      '30664',
      '30668',
      '30670',
      '30673',
      '30674',
      '30676',
      '30682',
      '30690',
      '30700',
      '30710',
      '30720',
      '30730',
      '30740',
      '30750',
      '30760',
      '30770',
      '30780',
      '30790',
      '30800',
      '30810',
      '30820',
      '30830',
      '30840',
      '30900',
      '30902',
      '30904',
      '30906',
      '30908',
      '30910',
      '30912',
      '30988',
      '30990',
      '31350',
      '31400',
      '31407',
      '31409',
      '31450',
      '31500',
      '31650'
    ],
    Jeram_P: ['31850'],
    Kampar: ['31900', '31907', '31909', '31910', '31920', '31950'],
    'Kampung Gajah': ['36800', '36810'],
    'Kampung Kepayang': ['31300'],
    Kamunting: ['34600'],
    'Kuala Kangsar': [
      '33000',
      '33007',
      '33009',
      '33010',
      '33020',
      '33030',
      '33040'
    ],
    'Kuala Kurau': ['34350'],
    'Kuala Sepetang': ['34650'],
    'Lambor Kanan': ['32900'],
    Langkap: ['36700'],
    Lenggong: ['33400', '33410', '33420'],
    Lumut: ['32200'],
    'Malim Nawar': ['31700'],
    Manong: ['33800'],
    Matang: ['34750'],
    'Padang Rengas': ['33700'],
    Pangkor: ['32300'],
    'Pantai Remis': ['34900'],
    Parit: ['32800'],
    'Parit Buntar': ['34200'],
    'Pengkalan Hulu': ['33100'],
    Pusing: ['31550', '31560'],
    Rantau_Panjang_P: ['34140'],
    Sauk: ['33500'],
    Selama: ['34100', '34120', '34130'],
    Selekoh: ['36200', '36207', '36209'],
    'Seri Manjong': ['32040'],
    Simpang: ['34700'],
    'Simpang Ampat Semanggol': ['34400'],
    Sitiawan: ['32000'],
    'Slim River': ['35800', '35820'],
    'Sungai Siput': ['31050', '31100'],
    'Sungai Sumun': ['36300', '36307', '36309'],
    Sungkai: ['35600'],
    'TLDM Lumut': ['32100'],
    Taiping: ['34000', '34007', '34008', '34009', '34010', '34020', '34030'],
    'Tanjong Malim': ['35900', '35907', '35909', '35910'],
    'Tanjong Piandang': ['34250'],
    'Tanjong Rambutan': ['31250'],
    'Tanjong Tualang': ['31800'],
    Tapah: ['35000', '35007', '35009'],
    'Tapah Road': ['35400'],
    'Teluk Intan': [
      '36000',
      '36007',
      '36008',
      '36009',
      '36010',
      '36020',
      '36030',
      '36110'
    ],
    Temoh: ['35350'],
    Trolak: ['35700'],
    Trong: ['34800'],
    Tronoh: ['31750'],
    'Ulu Bernam': ['36500'],
    'Ulu Kinta': ['31150']
  },
  Perlis: {
    Arau: ['02600', '02607', '02609'],
    'Kaki Bukit': ['02200'],
    Kangar: [
      '01000',
      '01007',
      '01009',
      '01500',
      '01502',
      '01503',
      '01504',
      '01505',
      '01506',
      '01508',
      '01512',
      '01514',
      '01516',
      '01517',
      '01518',
      '01524',
      '01529',
      '01532',
      '01538',
      '01540',
      '01546',
      '01550',
      '01551',
      '01556',
      '01560',
      '01564',
      '01570',
      '01572',
      '01576',
      '01578',
      '01582',
      '01586',
      '01590',
      '01592',
      '01594',
      '01596',
      '01598',
      '01600',
      '01604',
      '01606',
      '01608',
      '01609',
      '01610',
      '01612',
      '01614',
      '01620',
      '01622',
      '01626',
      '01628',
      '01630',
      '01632',
      '01634',
      '01644',
      '01646',
      '01648',
      '01660',
      '01664',
      '01670',
      '01672',
      '01673',
      '01674',
      '01676',
      '01680',
      '01694',
      '02400',
      '02450',
      '02500'
    ],
    'Kuala Perlis': ['02000'],
    'Padang Besar': ['02100'],
    Simpang_Ampat_PR: ['02700', '02707', '02709', '02800']
  },
  Sabah: {
    Beaufort: ['89800', '89807', '89808', '89809'],
    Beluran: ['90100', '90107', '90109'],
    Beverly: ['88700', '89260'],
    Bongawan: ['89700', '89707', '89708', '89709'],
    Inanam: ['88857'],
    Keningau: ['89000', '89007', '89008', '89009'],
    'Kota Belud': ['89150', '89157', '89158', '89159'],
    'Kota Kinabalu': [
      '88000',
      '88100',
      '88200',
      '88206',
      '88300',
      '88400',
      '88450',
      '88460',
      '88500',
      '88502',
      '88504',
      '88505',
      '88506',
      '88508',
      '88510',
      '88512',
      '88514',
      '88516',
      '88518',
      '88520',
      '88526',
      '88527',
      '88532',
      '88534',
      '88538',
      '88540',
      '88546',
      '88550',
      '88551',
      '88552',
      '88554',
      '88556',
      '88558',
      '88560',
      '88562',
      '88564',
      '88566',
      '88568',
      '88570',
      '88572',
      '88576',
      '88580',
      '88582',
      '88586',
      '88590',
      '88592',
      '88594',
      '88596',
      '88598',
      '88600',
      '88602',
      '88604',
      '88606',
      '88608',
      '88609',
      '88610',
      '88612',
      '88614',
      '88617',
      '88618',
      '88620',
      '88621',
      '88622',
      '88624',
      '88626',
      '88628',
      '88630',
      '88632',
      '88634',
      '88644',
      '88646',
      '88648',
      '88656',
      '88658',
      '88660',
      '88661',
      '88662',
      '88670',
      '88672',
      '88673',
      '88675',
      '88676',
      '88680',
      '88690',
      '88757',
      '88758',
      '88759',
      '88760',
      '88761',
      '88762',
      '88763',
      '88764',
      '88765',
      '88766',
      '88767',
      '88768',
      '88769',
      '88770',
      '88771',
      '88772',
      '88773',
      '88774',
      '88775',
      '88776',
      '88777',
      '88778',
      '88779',
      '88780',
      '88781',
      '88782',
      '88783',
      '88784',
      '88785',
      '88786',
      '88787',
      '88788',
      '88789',
      '88790',
      '88800',
      '88801',
      '88802',
      '88803',
      '88804',
      '88805',
      '88806',
      '88807',
      '88808',
      '88809',
      '88810',
      '88811',
      '88812',
      '88813',
      '88814',
      '88815',
      '88816',
      '88817',
      '88818',
      '88819',
      '88820',
      '88821',
      '88822',
      '88823',
      '88824',
      '88825',
      '88826',
      '88827',
      '88828',
      '88829',
      '88830',
      '88831',
      '88832',
      '88833',
      '88834',
      '88835',
      '88836',
      '88837',
      '88838',
      '88839',
      '88840',
      '88841',
      '88842',
      '88843',
      '88844',
      '88845',
      '88846',
      '88847',
      '88848',
      '88849',
      '88850',
      '88851',
      '88852',
      '88853',
      '88854',
      '88855',
      '88860',
      '88861',
      '88862',
      '88863',
      '88865',
      '88866',
      '88867',
      '88868',
      '88869',
      '88870',
      '88871',
      '88872',
      '88873',
      '88874',
      '88875',
      '88900',
      '88901',
      '88902',
      '88903',
      '88904',
      '88905',
      '88906',
      '88988',
      '88990',
      '88991',
      '88992',
      '88993',
      '88994',
      '88995',
      '88996',
      '88997',
      '88998',
      '88999'
    ],
    'Kota Kinabatangan': ['90200'],
    'Kota Marudu': ['89100', '89107', '89108', '89109'],
    'Kuala Penyu': ['89740', '89747', '89748', '89749'],
    Kudat: ['89050', '89057', '89058', '89059'],
    Kunak: ['91200', '91207', '91209'],
    'Lahad Datu': [
      '91100',
      '91109',
      '91110',
      '91111',
      '91112',
      '91113',
      '91114',
      '91115',
      '91116',
      '91117',
      '91118',
      '91119',
      '91120',
      '91121',
      '91122',
      '91123',
      '91124',
      '91125',
      '91126',
      '91127',
      '91128',
      '91150'
    ],
    Likas: ['88856'],
    Membakut: ['89720', '89727', '89728', '89729'],
    Menumbok: ['89760', '89767', '89768', '89769'],
    Nabawan: ['89950', '89957', '89958', '89959'],
    Pamol: ['90400'],
    Papar: ['89600', '89607', '89608', '89609'],
    Penampang: ['89500', '89507', '89508', '89509'],
    Putatan: ['88721', '88722', '88723', '88724', '88725'],
    Ranau: ['89300', '89307', '89308', '89309'],
    Sandakan: [
      '90000',
      '90009',
      '90300',
      '90307',
      '90700',
      '90701',
      '90702',
      '90703',
      '90704',
      '90705',
      '90706',
      '90707',
      '90708',
      '90709',
      '90711',
      '90712',
      '90713',
      '90714',
      '90715',
      '90716',
      '90717',
      '90718',
      '90719',
      '90720',
      '90721',
      '90722',
      '90723',
      '90724',
      '90725',
      '90726',
      '90727',
      '90728',
      '90729',
      '90730',
      '90731',
      '90732',
      '90733',
      '90734',
      '90735',
      '90736',
      '90737',
      '90738',
      '90739',
      '90740',
      '90741'
    ],
    Semporna: ['91300', '91307', '91308', '91309'],
    Sipitang: ['89850', '89857', '89858', '89859'],
    Tambunan: ['89650', '89657', '89658', '89659'],
    Tamparuli: ['89250', '89257', '89258', '89259'],
    'Tanjung Aru': ['88858'],
    Tawau: [
      '91000',
      '91007',
      '91008',
      '91009',
      '91010',
      '91011',
      '91012',
      '91013',
      '91014',
      '91015',
      '91016',
      '91017',
      '91018',
      '91019',
      '91020',
      '91021',
      '91022',
      '91023',
      '91024',
      '91025',
      '91026',
      '91027',
      '91028',
      '91029',
      '91030',
      '91031',
      '91032',
      '91033',
      '91034',
      '91035'
    ],
    Tenom: ['89900', '89907', '89908', '89909'],
    Tuaran: ['89200', '89207', '89208', '89209']
  },
  Sarawak: {
    Asajaya: ['94600'],
    Balingian: ['96350'],
    Baram: ['98050', '98057', '98058', '98059'],
    Bau: ['94000', '94007', '94009'],
    Bekenu: ['98150', '98157', '98159'],
    Belaga: ['96900', '96950'],
    Belawai: ['96150'],
    Betong: ['95700', '95707', '95709'],
    Bintangor: ['96500', '96507', '96508', '96509'],
    Bintulu: [
      '97000',
      '97007',
      '97008',
      '97009',
      '97010',
      '97011',
      '97012',
      '97013',
      '97014',
      '97015',
      '97300'
    ],
    Dalat: ['96300', '96307', '96309'],
    Daro: ['96200'],
    Debak: ['95500'],
    Engkilili: ['95800'],
    Julau: ['96600'],
    Kabong: ['94650'],
    Kanowit: ['96700', '96707', '96709'],
    Kapit: ['96800', '96807', '96809'],
    'Kota Samarahan': ['94300'],
    Kuching: [
      '93000',
      '93010',
      '93050',
      '93100',
      '93150',
      '93200',
      '93250',
      '93300',
      '93350',
      '93400',
      '93450',
      '93500',
      '93502',
      '93503',
      '93504',
      '93505',
      '93506',
      '93507',
      '93508',
      '93514',
      '93516',
      '93517',
      '93518',
      '93519',
      '93520',
      '93527',
      '93529',
      '93532',
      '93540',
      '93550',
      '93551',
      '93552',
      '93554',
      '93556',
      '93558',
      '93560',
      '93564',
      '93566',
      '93570',
      '93572',
      '93576',
      '93578',
      '93582',
      '93586',
      '93590',
      '93592',
      '93594',
      '93596',
      '93600',
      '93604',
      '93606',
      '93608',
      '93609',
      '93610',
      '93612',
      '93614',
      '93618',
      '93619',
      '93620',
      '93626',
      '93628',
      '93632',
      '93634',
      '93648',
      '93658',
      '93660',
      '93661',
      '93662',
      '93670',
      '93672',
      '93677',
      '93690',
      '93694',
      '93700',
      '93702',
      '93704',
      '93706',
      '93708',
      '93710',
      '93712',
      '93714',
      '93716',
      '93718',
      '93720',
      '93722',
      '93724',
      '93726',
      '93728',
      '93730',
      '93732',
      '93734',
      '93736',
      '93738',
      '93740',
      '93742',
      '93744',
      '93746',
      '93748',
      '93750',
      '93752',
      '93754',
      '93756',
      '93758',
      '93760',
      '93762',
      '93764',
      '93900',
      '93902',
      '93904',
      '93906',
      '93908',
      '93910',
      '93912',
      '93914',
      '93916',
      '93990'
    ],
    Lawas: ['98850', '98857', '98859'],
    Limbang: ['98700', '98707', '98708', '98709'],
    Lingga: ['94900'],
    'Long Lama': ['98300'],
    'Lubok Antu': ['95900'],
    Lundu: ['94500', '94507', '94509'],
    Lutong: ['98100', '98107', '98109'],
    Matu: ['96250'],
    Miri: ['98000', '98007', '98008', '98009'],
    Mukah: ['96400', '96410'],
    'Nanga Medamit': ['98750'],
    Niah: ['98200'],
    Pusa: ['94950'],
    Roban: ['95300'],
    Saratok: ['95400', '95407', '95409'],
    Sarikei: ['96100', '96107', '96108', '96109'],
    Sebauh: ['97100'],
    Sebuyau: ['94850'],
    Serian: ['94700', '94707', '94709', '94750', '94760'],
    Sibu: ['96000', '96007', '96008', '96009'],
    Siburan: ['94200'],
    Simunjan: ['94800', '94807', '94809'],
    Song: ['96850'],
    Spaoh: ['95600'],
    'Sri Aman': ['95000', '95007', '95008', '95009'],
    Sundar: ['98800'],
    Tatau: ['97200']
  },
  Selangor: {
    Ampang: ['68000'],
    'Bandar Baru Bangi': ['43600', '43650'],
    'Bandar Puncak Alam': ['42300'],
    Banting: ['42700'],
    'Batang Kali': ['44300'],
    'Batu Arang': ['48100'],
    'Batu Caves': ['68100'],
    Beranang: ['43700'],
    'Bestari Jaya': ['45600', '45607', '45609', '45620'],
    'Bukit Rotan': ['45700'],
    Cheras: ['43200', '43207'],
    Cyberjaya: ['63000', '63100', '63200', '63300'],
    Dengkil: ['43800', '43807'],
    'Hulu Langat': ['43100'],
    Jenjarom: ['42600', '42610'],
    Jeram_S: ['45800'],
    KLIA: ['64000'],
    Kajang: ['43000', '43007', '43009', '43558'],
    Kapar: ['42200'],
    Kerling: ['44100'],
    Klang: [
      '41000',
      '41050',
      '41100',
      '41150',
      '41200',
      '41250',
      '41300',
      '41400',
      '41506',
      '41560',
      '41586',
      '41672',
      '41700',
      '41710',
      '41720',
      '41900',
      '41902',
      '41904',
      '41906',
      '41908',
      '41910',
      '41912',
      '41914',
      '41916',
      '41918',
      '41990',
      '42100'
    ],
    'Kuala Kubu Baru': ['44000', '44010', '44020', '44110'],
    'Kuala Selangor': ['45000'],
    'Pelabuhan Klang': ['42000', '42009'],
    'Petaling Jaya': [
      '46000',
      '46050',
      '46100',
      '46150',
      '46200',
      '46300',
      '46350',
      '46400',
      '46506',
      '46547',
      '46549',
      '46551',
      '46564',
      '46582',
      '46598',
      '46662',
      '46667',
      '46668',
      '46672',
      '46675',
      '46700',
      '46710',
      '46720',
      '46730',
      '46740',
      '46750',
      '46760',
      '46770',
      '46780',
      '46781',
      '46782',
      '46783',
      '46784',
      '46785',
      '46786',
      '46787',
      '46788',
      '46789',
      '46790',
      '46791',
      '46792',
      '46793',
      '46794',
      '46795',
      '46796',
      '46797',
      '46798',
      '46799',
      '46800',
      '46801',
      '46802',
      '46803',
      '46804',
      '46805',
      '46806',
      '46860',
      '46870',
      '46960',
      '46962',
      '46964',
      '46966',
      '46968',
      '46970',
      '46972',
      '46974',
      '46976',
      '46978',
      '47300',
      '47301',
      '47307',
      '47308',
      '47400',
      '47410',
      '47800',
      '47810',
      '47820',
      '47830'
    ],
    Puchong: [
      '47100',
      '47110',
      '47120',
      '47130',
      '47140',
      '47150',
      '47160',
      '47170',
      '47180',
      '47190'
    ],
    'Pulau Carey': ['42960'],
    'Pulau Indah': ['42920'],
    'Pulau Ketam': ['42940'],
    Rasa: ['44200'],
    Rawang: ['48000', '48010', '48020', '48050', '48300'],
    'Sabak Bernam': ['45200', '45207', '45209'],
    Sekinchan: ['45400'],
    Semenyih: ['43500'],
    Sepang: ['43900', '43909'],
    Serdang_S: ['43400'],
    Serendah: ['48200'],
    'Seri Kembangan': ['43300'],
    'Shah Alam': [
      '40000',
      '40100',
      '40150',
      '40170',
      '40200',
      '40300',
      '40400',
      '40450',
      '40460',
      '40470',
      '40500',
      '40502',
      '40503',
      '40505',
      '40512',
      '40517',
      '40520',
      '40529',
      '40542',
      '40548',
      '40550',
      '40551',
      '40560',
      '40564',
      '40570',
      '40572',
      '40576',
      '40578',
      '40582',
      '40590',
      '40592',
      '40594',
      '40596',
      '40598',
      '40604',
      '40607',
      '40608',
      '40610',
      '40612',
      '40620',
      '40622',
      '40626',
      '40632',
      '40646',
      '40648',
      '40660',
      '40664',
      '40670',
      '40672',
      '40673',
      '40674',
      '40675',
      '40676',
      '40680',
      '40690',
      '40700',
      '40702',
      '40704',
      '40706',
      '40708',
      '40710',
      '40712',
      '40714',
      '40716',
      '40718',
      '40720',
      '40722',
      '40724',
      '40726',
      '40728',
      '40730',
      '40732',
      '40800',
      '40802',
      '40804',
      '40806',
      '40808',
      '40810',
      '40990'
    ],
    'Subang Jaya': [
      '47200',
      '47500',
      '47507',
      '47600',
      '47610',
      '47620',
      '47630',
      '47640',
      '47650'
    ],
    'Sungai Ayer Tawar': ['45100'],
    'Sungai Besar': ['45300'],
    'Sungai Buloh': ['40160', '47000'],
    'Sungai Pelek': ['43950'],
    'Tanjong Karang': ['45500'],
    'Tanjong Sepat': ['42800'],
    'Telok Panglima Garang': ['42425', '42500', '42507', '42509']
  },
  Terengganu: {
    Ajil: ['21800', '21810', '21820'],
    'Al Muktatfi Billah Shah': ['23400'],
    'Ayer Puteh': ['24050'],
    'Bukit Besi': ['23200'],
    'Bukit Payong': ['21400'],
    Ceneh: ['24060'],
    Chalok: ['21450'],
    Cukai: ['24000', '24007', '24009'],
    Dungun: ['23000', '23007', '23009', '23050'],
    Jerteh: ['22000', '22010', '22020'],
    'Kampung Raja': ['22200'],
    Kemasek: ['24200', '24207', '24209'],
    Kerteh: ['24300'],
    'Ketengah Jaya': ['23300'],
    Kijal: ['24100', '24107', '24109'],
    'Kuala Berang': ['21700'],
    'Kuala Besut': ['22300', '22307', '22309'],
    'Kuala Terengganu': [
      '20000',
      '20050',
      '20100',
      '20200',
      '20300',
      '20400',
      '20500',
      '20502',
      '20503',
      '20504',
      '20505',
      '20506',
      '20508',
      '20512',
      '20514',
      '20516',
      '20517',
      '20518',
      '20519',
      '20520',
      '20532',
      '20534',
      '20536',
      '20538',
      '20540',
      '20542',
      '20546',
      '20548',
      '20550',
      '20551',
      '20552',
      '20554',
      '20556',
      '20560',
      '20564',
      '20566',
      '20568',
      '20570',
      '20572',
      '20576',
      '20578',
      '20582',
      '20586',
      '20590',
      '20592',
      '20596',
      '20600',
      '20604',
      '20606',
      '20608',
      '20609',
      '20610',
      '20612',
      '20614',
      '20618',
      '20620',
      '20622',
      '20626',
      '20628',
      '20630',
      '20632',
      '20646',
      '20648',
      '20656',
      '20658',
      '20660',
      '20661',
      '20662',
      '20664',
      '20668',
      '20670',
      '20672',
      '20673',
      '20674',
      '20676',
      '20680',
      '20690',
      '20698',
      '20700',
      '20710',
      '20720',
      '20900',
      '20902',
      '20904',
      '20906',
      '20908',
      '20910',
      '20912',
      '20914',
      '20916',
      '20918',
      '20920',
      '20922',
      '20924',
      '20926',
      '20928',
      '20930',
      '20990',
      '21000',
      '21009',
      '21010',
      '21020',
      '21030',
      '21040',
      '21060',
      '21070',
      '21080',
      '21090',
      '21100',
      '21109',
      '21200',
      '21209',
      '21210',
      '21220',
      '21300',
      '21309'
    ],
    Marang: ['21600', '21610'],
    Paka: ['23100'],
    Permaisuri: ['22100', '22107', '22109', '22110', '22120'],
    'Sungai Tong': ['21500']
  },
  'WP Labuan ': {
    Labuan: [
      '87000',
      '87010',
      '87011',
      '87012',
      '87013',
      '87014',
      '87015',
      '87016',
      '87017',
      '87018',
      '87019',
      '87020',
      '87021',
      '87022',
      '87023',
      '87024',
      '87025',
      '87026',
      '87027',
      '87028',
      '87029',
      '87030',
      '87031',
      '87033'
    ]
  },
  'WP Putrajaya': {
    Putrajaya: [
      '62000',
      '62007',
      '62050',
      '62100',
      '62150',
      '62200',
      '62250',
      '62300',
      '62502',
      '62504',
      '62505',
      '62506',
      '62510',
      '62512',
      '62514',
      '62516',
      '62517',
      '62518',
      '62519',
      '62520',
      '62522',
      '62524',
      '62526',
      '62527',
      '62530',
      '62532',
      '62536',
      '62540',
      '62542',
      '62546',
      '62550',
      '62551',
      '62570',
      '62574',
      '62576',
      '62582',
      '62584',
      '62590',
      '62592',
      '62596',
      '62602',
      '62604',
      '62605',
      '62606',
      '62616',
      '62618',
      '62620',
      '62623',
      '62624',
      '62628',
      '62630',
      '62632',
      '62648',
      '62652',
      '62654',
      '62662',
      '62668',
      '62670',
      '62674',
      '62675',
      '62676',
      '62677',
      '62686',
      '62692',
      '62988'
    ]
  }
};
