import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';

import './CompContainer.scss';
import twogirl from 'assets/images/2girls.png';
import womandesktop from 'assets/images/asian-businesswoman-using-smart-phone-against-car-desktop.png';
import DataLayer from 'hooks/DataLayerPush';
import ScrollTo from 'hooks/ScrollTo';
import { Translate } from 'hooks/Translation';

function CompContainer() {
  const { t } = Translate();
  const { scrollToElement } = ScrollTo();
  const { sendEventData } = DataLayer();
  return (
    <div id="section3">
      <div className="CompContainer d-flex flex-row-reverse flex-wrap row pt-lg-5 pt-md-5 pt-sm-1 pt-xs-1 mx-0">
        <div className="right-sec col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
          <picture>
            <source
              media="(min-width:1200px)"
              srcSet={twogirl}
              alt="womandesktop"
            />
            <source
              media="(max-width:1200px)"
              srcSet={twogirl}
              alt="womanmobile"
            />
            <img src={womandesktop} alt="person" />
          </picture>
        </div>
        <div className="left-sec col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="title pr-1">
            <h1 className="comp-title bold">
              {t('comp_title_1')}
              <span>{t('comp_title_2')}</span>.
            </h1>
            <br />
            <p className="comp-title-3">{t('comp_title_3')}</p>
          </div>
          <div className="scroll">
            <div className="parag">
              <div className="medium">{t('comp_title_3_1')}</div>
              <div className="medium">{t('comp_title_3_2')}</div>
              <div className="medium">{t('comp_title_3_3')}</div>
            </div>
          </div>
          <div className="link hover">
            <a
              className="medium goFaq"
              data-event="go_to_page"
              data-event-category="Home"
              data-event-action="Go To Page"
              data-event-label="Need to know more? Read our FAQ"
              onClick={(e) => {
                sendEventData(e);
                scrollToElement('faq');
              }}
            >
              <span className="p-2">
                <ArrowRight />
              </span>
              {t('comp_link')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompContainer;
