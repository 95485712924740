import React from 'react';
import { ExclamationCircle } from 'react-bootstrap-icons';

import './AddOns.scss';
import AddOnsLogic from './Logic/AddOns.logic';
import {
  CheckBoxContainer,
  InputToggle,
  LogoInsurer,
  PaymentBox
} from 'components';
import { MainContainer } from 'components/UIComponent';
import { formatNumber } from 'utils/helper';

function AddOns() {
  const {
    addOnData,
    addOnEvent,
    benefitCodeToInfo,
    benefitCodeToTitle,
    closeModal,
    companyCode,
    getAddOnsList,
    getOptions,
    modalProps,
    moveNext,
    selectAll,
    showModal,
    t,
    toggle,
    updateAddOn
  } = AddOnsLogic();

  return (
    <MainContainer
      isLoading={showModal}
      modalProps={modalProps}
      handleClose={closeModal}
    >
      <div className="addons">
        <div className="options">
          <div className="mb-4">
            <div className="mt-2">
              <LogoInsurer companyCode={companyCode} />
            </div>
            {getAddOnsList() ? (
              <div className="addons-more">
                <div className="mt-4 mb-4">{t('add_on')}</div>
                <div className="toggle">
                  <InputToggle
                    label={t('select_all')}
                    toggle={selectAll}
                    check={toggle}
                  ></InputToggle>
                </div>
              </div>
            ) : null}
            <div className="more">
              {getAddOnsList()?.map((addon, index) => {
                const { benCode, benPremium, sumInsured } = addon;
                return (
                  <div key={benCode}>
                    <CheckBoxContainer
                      title={t(benefitCodeToTitle?.[benCode])}
                      info={t(benefitCodeToInfo?.[benCode])}
                      val={benCode}
                      options={getOptions(benCode, sumInsured)}
                      amount={formatNumber(benPremium)}
                      handleChangeEvent={addOnEvent}
                      handleClick={(e) => {
                        e.preventDefault();
                        updateAddOn(benCode);
                      }}
                      addOnData={addOnData}
                    />
                  </div>
                );
              }) || (
                <div className="addons-not-match text-center">
                  <ExclamationCircle
                    color={'#5698FC'}
                    size={60}
                    style={{
                      border: '0',
                      borderRadius: '0%',
                      backgroundColor: '#E7F6FF',
                      padding: '10px'
                    }}
                  />
                  <div className="label">{t('error6')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <PaymentBox handleClick={moveNext} />
      </div>
    </MainContainer>
  );
}

export default AddOns;
