import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  '03': undefined,
  '01': undefined,
  isLoading: {}
};

export const premiumSlice = createSlice({
  name: 'premium',
  initialState,
  reducers: {
    setPremium: (state, action) => {
      const { items, key, sumInsured } = action.payload;
      if (!state[key]) {
        state[key] = {};
      }
      state[key][sumInsured] = items;
    },
    setLoading: (state, action) => {
      const { key, sumInsured, value } = action.payload;
      if (!state.isLoading[key]) {
        state.isLoading[key] = {};
      }
      state.isLoading[key][sumInsured] = value;
    },
    resetLoading: (state, action) => {
      state.isLoading = initialState.isLoading;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
    builder.addCase('loadingAll', (state) => {
      state.isLoading = {
        0: {
          sumInsured: true,
          reducedSumInsured: true
        },
        1: {
          sumInsured: true,
          reducedSumInsured: true
        },
        2: {
          sumInsured: true,
          reducedSumInsured: true
        }
      };
    });
  }
});

export const { resetLoading, setLoading, setPremium } = premiumSlice.actions;

export default premiumSlice.reducer;
