import { useEffect, useState } from 'react';

import { insuranceData } from './Checkout.reducer';
import useCleverTapPush from 'hooks/CleverTapData';
import useMobileSize from 'hooks/IsMobile';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';
import {
  useFinalPlanMutation,
  usePaymentGatewayMutation
} from 'services/api/services';
import {
  finalInsuranceRequest,
  initPaymentReq
} from 'services/decorator/request';

const CheckoutLogic = () => {
  const [paymentFormHTML, setPaymentFormHTML] = useState(null);
  const { t } = Translate();
  const dispatch = useAppDispatch();
  const [finalPlan] = useFinalPlanMutation();
  const [paymentGateway] = usePaymentGatewayMutation();
  const initPaymentData = useAppSelector(initPaymentReq);
  const [isLoading, setLoading] = useState();
  const { isMobileView } = useMobileSize();

  const vehicleForm = useAppSelector((state) => state.vehicleForm);
  const { confirmVariation, vix } = useAppSelector((state) => state.vix);
  const { referralCode } = useAppSelector((state) => state.base);
  const { selectedData, selectedPlan } = useAppSelector((state) => state.plans);
  const finalData = useAppSelector(finalInsuranceRequest);
  const { currentRoute } = useStepper();
  const { pushData } = useCleverTapPush(currentRoute);

  useEffect(() => {
    pushData();
  }, []);

  useEffect(() => {
    if (paymentFormHTML) {
      const div = document.createElement('div');
      div.style = 'display: none;';
      div.innerHTML = paymentFormHTML;
      document.body.appendChild(div);

      const submitBtn = document.querySelector('input[name="submit"]');
      if (submitBtn) {
        submitBtn.id = 'submit_btn';
        const payment = document.getElementById('submit_btn');
        payment.click();
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [paymentFormHTML]);

  const getIcFormat = (ic) => {
    return ic?.slice(0, 6) + '-' + ic?.slice(6, 8) + '-' + ic?.slice(8, 12);
  };

  const sendFinalPlan = async () => {
    setLoading(true);
    try {
      const { data } = await finalPlan(finalData).unwrap();
      dispatch(insuranceData(data));
      const paymentResponse = await paymentGateway({
        ...initPaymentData,
        order_reference: data.id,
        order_total_amount: `${data.grossDue2}`
      }).unwrap();
      if (paymentResponse.code === 'S0000') {
        setPaymentFormHTML(paymentResponse.data.payment_gateway_form);
      } else {
        console.error(
          'Payment initialization failed:',
          paymentResponse.message
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return {
    t,
    vix,
    vehicleForm,
    getIcFormat,
    confirmVariation,
    referralCode,
    sendFinalPlan,
    isLoading,
    pdfUrl: selectedData?.resExtraParam?.item?.[1].value,
    selectedPlan,
    isMobileView
  };
};

export default CheckoutLogic;
