export const style = {
  title: {
    fontFamily: 'bold'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  coverContainer: (active) => ({
    border: active ? '2px solid #5698FC' : '1px solid #e5e5e5',
    backgroundColor: active ? '#e7f6ff' : 'white',
    borderRadius: '5px',
    padding: '10px',
    gap: '12px',
    boxShadow: '0px 4px 7px 3px rgba(208, 220, 255, 0.40)'
  })
};
