import React from 'react';

import './BrandList.scss';
import {
  Allianz,
  Etiqa,
  RHBPromo,
  Tune,
  Zurich,
  ZurichTakaful
} from 'assets/images';

function BrandList() {
  return (
    <div className="row justify-content-md-center brandlist">
      <div className="logo-container col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <ul className="d-flex justify-content-evenly mt-4">
          <li>
            <img src={Allianz} width={'150px'} alt="Motor" />
          </li>
          <li
            className="clickable"
            onClick={() =>
              window.open(
                'https://www.pos.com.my/rhbi-customer-campaign',
                '_blank'
              )
            }
          >
            <img
              src={RHBPromo}
              width={'150px'}
              alt="Motor"
              className="hover-effect"
            />
          </li>
          {/* <li>
                <img src={rhb_logo} width={'150px'} alt="Motor" />
              </li> */}
          <li>
            <img src={Zurich} width={'150px'} alt="Motor" />
          </li>
          <li>
            <img src={ZurichTakaful} width={'140px'} alt="Motor" />
          </li>
          <li>
            <img className="tune" src={Tune} width={'40px'} alt="Motor" />
          </li>
          <li>
            <img className="etiqa" src={Etiqa} width={'100px'} alt="Motor" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BrandList;
