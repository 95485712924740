import { Amplify, Auth } from 'aws-amplify';
import { saveCookie } from 'utils/cookieStorage';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APPCLIENTID
  }
});

export const signingIn = async () => {
  try {
    await Auth.signIn({
      username: process.env.REACT_APP_USERNAME,
      password: process.env.REACT_APP_PASSWORD
    }).then((data) => {
      saveCookie('api_token', data.signInUserSession.idToken.jwtToken);
    });
  } catch (error) {
    console.log('error signing up:', error);
  }
};
