export const style = {
  headerTitle: {
    fontFamily: 'bold'
  },
  radioBoxContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 15fr'
  },
  checkBox: { paddingTop: '5px' },
  title: {
    fontFamily: 'medium'
  }
};
