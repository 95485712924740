import React from 'react';

import VehicleFormLogic from '../Logic/VehicleForm.logic';
import { Button, Spinner } from 'components/UIComponent';
import { links } from 'constants/label';

const Footer = () => {
  const { isLoading, t } = VehicleFormLogic();
  return (
    <div className="next-button-container footer d-flex justify-content-center justify-content-lg-end flex-wrap flex-xl-row flex-column">
      <div className="d-flex flex-column-reverse flex-xl-row mt-xl-0">
        <div className="desc mt-2 d-flex flex-column align-items-center align-items-xl-end">
          <div className="label">{t('v_f_b_label_1')}</div>
          <div className="label">
            {t('v_f_b_label_2')}
            <span className="highlight subBlueTitle">
              <a href={links.TNC} target="_blank" rel="noreferrer">
                {t('v_f_b_label_3')}
              </a>
            </span>
            <span className="highlight">and</span>
            <span className="highlight subBlueTitle">
              <a href={links.PRIVACYSTATEMENT} target="_blank" rel="noreferrer">
                {t('v_f_b_label_4')}
              </a>
            </span>
            .
          </div>
        </div>
        <div className="d-flex justify-content-center next-button-wrapper">
          <Button
            type="submit"
            className="button-next hover"
            id="vehicleForm"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t('v_f_b')
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
