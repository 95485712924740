import React from 'react';
import { FormProvider } from 'react-hook-form';

import './VehicleForm.scss';
import { VehicleFormLogic } from './Logic/VehicleForm.logic';
import {
  InputCalendar,
  InputDropdownText,
  InputGender,
  InputMaritalStatus,
  InputMobile,
  InputText
} from 'components';
import { Form, MainContainer, Row } from 'components/UIComponent';
import { Footer } from 'pages';

export const VehicleForm = ({ isOpen }) => {
  const {
    callBack,
    closeModal,
    divRef,
    errors,
    formError,
    isLoading,
    isPassport,
    language,
    modalProps,
    onError,
    onSubmit,
    register,
    rules,
    t,
    useFormMethod
  } = VehicleFormLogic();

  return (
    <MainContainer
      isLoading={isLoading}
      modalProps={modalProps}
      handleClose={closeModal}
    >
      {isOpen ? (
        <div
          id="section2"
          className={
            'vehicle-form d-flex flex-column vhlBg pt-4 pt-md-5 px-4 px-md-5 pb-5'
          }
        >
          <h5 className="mb-4">{t('v_f_title')}</h5>
          <FormProvider {...useFormMethod}>
            <Form onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}>
              <Row className="mb-3">
                <InputText
                  language={language}
                  fieldLg="4"
                  fieldMg="6"
                  detectEmpty={() => null}
                  maxLength="20"
                  fieldControlId="validationCustom01"
                  floatingControlId="floatingInput"
                  floatingLabel={t('v_f_reg_no')}
                  fieldType="text"
                  fieldPlaceholder={t('v_f_reg_no')}
                  formError={formError}
                  register={{
                    control: 'regNo',
                    rules: rules.regNo
                  }}
                />
                <InputText
                  fieldLg="4"
                  fieldMg="6"
                  maxLength="5"
                  detectEmpty={() => null}
                  fieldControlId="validationCustom02"
                  floatingControlId="floatingInput"
                  floatingLabel={t('v_f_poscode')}
                  fieldType="text"
                  fieldPlaceholder={t('v_f_poscode')}
                  formError={formError}
                  register={{
                    control: 'postcode',
                    rules: rules.postcode
                  }}
                />
                <InputDropdownText
                  fieldLg="4"
                  fieldMg="6"
                  firstOption="NRIC"
                  fieldControlId="validationCustom03"
                  floatingControlId="floatingInput"
                  floatingLabel={t('v_f_ic_type')}
                  fieldType={'text'}
                  fieldPlaceholder={t('v_f_ic_num')}
                  formError={formError}
                  handleCallBack={callBack}
                  maxLength={isPassport ? '15' : '12'}
                  isPassport={isPassport}
                  register={
                    !isPassport
                      ? {
                          control: 'newIc',
                          rules: rules.newIc
                        }
                      : {
                          control: 'passport',
                          rules: rules.passport
                        }
                  }
                />
                {isPassport ? (
                  <>
                    <InputCalendar
                      fieldLg="4"
                      fieldMg="6"
                      fieldControlId="validationCustom04"
                      floatingControlId="floatingInput"
                      floatingLabel={t('v_f_birthDate')}
                      fieldType="text"
                      fieldPlaceholder={t('v_f_birthDate')}
                      formError={formError}
                      register={{
                        control: 'birthDate',
                        rules: rules.birthDate
                      }}
                    />
                    <InputGender
                      register={register}
                      errors={errors}
                      rules={rules}
                    />
                  </>
                ) : null}
                <InputMaritalStatus
                  register={register}
                  errors={errors}
                  rules={rules}
                />
                <InputMobile classes="col-lg-4 col-md-6" ref={divRef}>
                  <InputText
                    showCountryCode={true}
                    fieldLg="12"
                    maxLength="10"
                    detectEmpty={() => null}
                    countryClass="countryClass"
                    fieldControlId="validationCustom01"
                    floatingControlId="floatingInput"
                    floatingLabel={t('v_f_mobile')}
                    fieldType="text"
                    fieldPlaceholder={t('v_f_mobile')}
                    countryCode="+60"
                    formError={formError}
                    register={{
                      control: 'mobileNumber',
                      rules: rules.mobileNumber
                    }}
                  />
                </InputMobile>
              </Row>
              <Row>
                <Footer isLoading={isLoading} t={t} />
              </Row>
            </Form>
          </FormProvider>
        </div>
      ) : null}
    </MainContainer>
  );
};

export default VehicleForm;
