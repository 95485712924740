import React from 'react';
import { useLocation } from 'react-router-dom';

import './bottom-footer.scss';
import { appLinks, socialLinks } from './Constant';
import Drhb from 'assets/images/DRB_HiCOM_Logo 2.svg';
import DataLayer from 'hooks/DataLayerPush';

export function BottomFooter() {
  const { pathname } = useLocation();
  const currentPathname = pathname.split('/')[1];
  const { sendEventData } = DataLayer();

  return (
    <>
      {currentPathname === '' && (
        <div className="bottom-footer">
          <div className="container-bottom">
            <div className="download">
              <div className="title medium">Download Our App</div>
              <div className="linksbinder">
                {appLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    data-event={'download_app'}
                    data-event-category={'Footer'}
                    data-event-action={'Click Download App'}
                    data-event-label={link.platform}
                    onClick={sendEventData}
                  >
                    <img
                      className={link.alt === 'app-gallery' ? link.alt : ''}
                      src={link.imgSrc}
                      alt={link.alt}
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="follow">
              <div className="title text-center medium">Follow us on</div>
              <div className="social">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    data-event={'go_to_social_media'}
                    data-event-category={'Footer'}
                    data-event-action={'Go To Social Media'}
                    data-event-label={link.platform}
                    onClick={sendEventData}
                  >
                    {link.component}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="copyright-container">
            <FooterCopyRight />
          </div>
        </div>
      )}
    </>
  );
}

export default BottomFooter;

const FooterCopyRight = () => {
  return (
    <div className="footer-copyright">
      <p>
        © 2024 Pos Malaysia Berhad 199101019653 (229990-M). All Rights Reserved.
        A member of <span>{<img src={Drhb} alt="drhb" />}</span>
      </p>
    </div>
  );
};
