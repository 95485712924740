import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showModal: undefined,
  modal: undefined
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setCloseModal: (state) => {},
    setModalProps: (state, action) => {
      state.modal = {
        ...action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase('resetModal', () => initialState);
    builder.addCase('reset', () => initialState);
  }
});

export const { setCloseModal, setInitialState, setModalProps, setShowModal } =
  modalSlice.actions;

export default modalSlice.reducer;
