import { setCoverageUpdate, setIsUpdate } from './CoverageOption.reducer';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useUpdateCoverageAddOnMutation } from 'services/api/services';
import { premiumRequest } from 'services/decorator/request';
import { setShowModal } from 'store/modal';

const CoverageOptionLogic = () => {
  const dispatch = useAppDispatch();
  const { isLoading, selectSumInsured } = useAppSelector((state) => state.base);
  const { coverType, planData, selectedPlan } = useAppSelector(
    (state) => state.plans
  );
  const { isUpdate, message, sumInsured } = useAppSelector(
    (state) => state.coverOption
  );
  const premium = useAppSelector(premiumRequest);
  const [updateCoverageAddOn] = useUpdateCoverageAddOnMutation();

  const confirmCoverage = async () => {
    await updateCoverageAddOn({
      ...premium,
      addBenData: {
        item: [
          {
            benCode: 'WSC',
            benDesc: 'Windscreen Coverage',
            sumInsured: Number(sumInsured)
          }
        ]
      },
      selectSumInsured
    });
    dispatch(setIsUpdate(false));
    dispatch(setShowModal(false));
  };

  const onChangeWSC = (e) => {
    const { value } = e.target;
    if (
      Number(value) ===
      planData?.[coverType]?.[selectSumInsured]?.[selectedPlan]?.[0]?.sumInsured
    ) {
      dispatch(setIsUpdate(false));
    } else {
      dispatch(setIsUpdate(true));
    }
    dispatch(setCoverageUpdate(value));
  };

  const errorValue = () => sumInsured < 500 || sumInsured > 5000;

  return {
    errorValue,
    isUpdate,
    message,
    isLoading,
    onChangeWSC,
    confirmCoverage,
    sumInsured,
    price:
      planData?.[coverType]?.[selectSumInsured]?.[selectedPlan]?.[0]?.benPremium
  };
};

export default CoverageOptionLogic;
