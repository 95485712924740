/** @jsxImportSource theme-ui */

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/lib/integration/react';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'utils/i18n';
import { App } from './App';
import { Themes } from './styles';
import setupStore from 'store/store';
import { ThemeUIProvider } from 'theme-ui';

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
);

const { persistor, store } = setupStore();

const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingMarkup}>
    <ThemeUIProvider theme={Themes.MainTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeUIProvider>
  </Suspense>
);
