import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  type: undefined
};

export const vhlTypeSlice = createSlice({
  name: 'vhlType',
  initialState,
  reducers: {
    setVhlType: (state, action) => {
      state.type = action.payload;
    },
    resetVehicleState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { resetVehicleState, setVhlType } = vhlTypeSlice.actions;

export default vhlTypeSlice.reducer;
