import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  driversData: undefined
};

export const additionalDriverSlice = createSlice({
  name: 'additionalDriver',
  initialState,
  reducers: {
    setDriversData: (state, action) => {
      state.driversData = action.payload;
    },
    setSelectedDrivers: (state, action) => {
      state.selectedDrivers = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { setDriversData, setSelectedDrivers } =
  additionalDriverSlice.actions;

export default additionalDriverSlice.reducer;
