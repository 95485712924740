export const regex = {
  ADDRESS: /^[\w\s,.#&'"@()/-]{1,100}$/,
  COMPANY_ADDRESS: /^[^,]*(,[^,]*){2,3}$/,
  COMPANY_NUMBER: /^[1-9]\d{7,9}$/,
  EMAIL: /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]+$/,
  IC_NUMBER:
    /(0[0-9]|[1-9][0-9])(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-6]|2[1-9]|[3-9][0-9])(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})([0-9])/gm,
  MOBILE_NUMBER: /^1\d{8,9}$/,
  PASSPORT_NUMBER: /^[a-zA-Z0-9-]{5,20}$/,
  POSTCODE: /^\d{5}$/
};
