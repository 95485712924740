/** @jsxImportSource theme-ui */
import React from 'react';

import './Status.Modal.scss';
import StatusModalLogic from './Logic/StatusModal.logic';
import { style } from './StatusModal.style';
import { ButtonUi } from 'components/UIComponent';

function StatusModal({ code, handleClick, refId, twoButtons, type }) {
  const { bgColor, btnLabel, footNote, Icon, message, t, title } =
    StatusModalLogic({
      type,
      code,
      refId
    });

  return (
    <div sx={style.container}>
      <div sx={style.iconContainer}>
        <div sx={style.icon(bgColor)}>{Icon}</div>
      </div>
      <div sx={style.bodyContainer}>
        <div sx={style.title}>{t(title)}</div>
        <div sx={style.message}>{message}</div>
      </div>
      <div>
        <ButtonUi twoButtons={twoButtons} handleClick={handleClick}>
          {t(btnLabel)}
        </ButtonUi>
      </div>
      <div sx={style.bodyContainer}>{footNote}</div>
    </div>
  );
}

export default StatusModal;
