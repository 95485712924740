import {
  AddOns,
  Checkout,
  CustomerDetails,
  Plans,
  UserVerification
} from 'components';
import { Home, PaymentStatus } from 'pages';

export const routes = [
  { route: '/', component: Home, exact: true },
  { route: '/user-verification', component: UserVerification, exact: true },
  { route: '/payment-status', component: PaymentStatus, exact: true }
];

export const stepperRoutes = [
  { route: '/plans', component: Plans, exact: true },
  { route: '/addons', component: AddOns, exact: true },
  { route: '/customer-details', component: CustomerDetails, exact: true },
  { route: '/checkout', component: Checkout, exact: true }
];
