import React from 'react';

import { InputRadioCheck } from 'components';
import useCheckComplete from 'hooks/CheckComplete';
import { Translate } from 'hooks/Translation';

const InputMaritalStatus = ({ errors, register, rules }) => {
  const {
    activeIndex,
    errorMessage,
    errorStatus,
    fieldBgColor,
    fieldIconStatus,
    handleActive
  } = useCheckComplete(errors, 'maritalStatus');
  const { t } = Translate();

  const maritalStatusOptions = [
    {
      label: 'v_f_marital_s',
      value: 'S',
      active: activeIndex === 'S'
    },
    {
      label: 'v_f_marital_m',
      value: 'M',
      active: activeIndex === 'M'
    }
  ];

  return (
    <InputRadioCheck
      title={t('v_f_marital')}
      options={maritalStatusOptions}
      errors={errors}
      register={register}
      name="maritalStatus"
      onClick={handleActive}
      fieldBgColor={fieldBgColor}
      fieldIconStatus={fieldIconStatus}
      errorMessage={errorMessage()}
      errorStatus={errorStatus()}
      t={t}
      rules={rules}
    />
  );
};

export default InputMaritalStatus;
