import { useEffect, useState } from 'react';

import { Moped, Sedan } from 'assets/images';
import { useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';

const VehicleLoadingLogic = () => {
  const { type } = useAppSelector((state) => state.vhlType);
  const { t } = Translate();
  const [countOfProgress, setCountOfProgress] = useState(0);
  const startPosition = 10;
  const maxLeftPosition = 80;
  const imageLeftPosition = `${Math.min(
    (countOfProgress + startPosition) * 0.7,
    maxLeftPosition
  )}%`;

  useEffect(() => {
    const timer = setInterval(() => {
      setCountOfProgress((oldProgress) => {
        if (oldProgress === 100) return 0;
        return Math.min(oldProgress + Math.random() * 4, 100);
      });
    }, 499);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const vehicleImage = {
    car: Sedan,
    motorcycle: Moped
  };

  const loadingSteps = {
    '/': 'loading_msg',
    '/user-verification': 'loading_otw',
    '/plans': 'loading_otw'
  };

  return {
    countOfProgress,
    imageLeftPosition,
    t,
    vehicleImage: vehicleImage[type] || Sedan,
    message: loadingSteps?.[window.location.pathname] || 'loading_otw'
  };
};

export default VehicleLoadingLogic;
