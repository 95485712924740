import React from 'react';

import { InputRadioCheck } from 'components';
import useCheckComplete from 'hooks/CheckComplete';
import { Translate } from 'hooks/Translation';

const InputGender = ({ errors, register, rules, style }) => {
  const {
    activeIndex,
    errorMessage,
    errorStatus,
    fieldBgColor,
    fieldIconStatus,
    handleActive
  } = useCheckComplete(errors, 'gender');
  const { t } = Translate();

  const genderOptions = [
    { label: 'v_f_gender_m', value: 'M', active: activeIndex === 'M' },
    { label: 'v_f_gender_f', value: 'F', active: activeIndex === 'F' }
  ];

  return (
    <InputRadioCheck
      style={style}
      title={t('v_f_gender')}
      options={genderOptions}
      errors={errors}
      register={register}
      name="gender"
      onClick={handleActive}
      fieldBgColor={fieldBgColor}
      fieldIconStatus={fieldIconStatus}
      t={t}
      errorMessage={errorMessage()}
      errorStatus={errorStatus()}
      rules={rules}
    />
  );
};

export default InputGender;
