import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { topNav } from 'constants/enum';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import useSaveQueryParams from 'hooks/SaveQueryParams';
import { useStepper } from 'hooks/Stepper';
import i18next from 'i18next';
import { setLanguage } from 'store/base';

const HeaderLogic = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.base.language);
  const [activeSubMenuLevel1, setActiveSubMenuLevel1] = useState(null);
  const [activeSubMenuLevel2, setActiveSubMenuLevel2] = useState(null);
  const [activeSubMenuLevel3, setActiveSubMenuLevel3] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { movePrev } = useStepper();
  const {
    getLangFromLocalStorage,
    getLangFromQueryStrings,
    saveLangToLocalStorage
  } = useSaveQueryParams();

  const location = useLocation();

  const languages = [
    {
      code: 'en',
      name: 'english',
      country_code: 'gb'
    },
    {
      code: 'my',
      name: 'bahasa',
      country_code: 'my'
    }
  ];

  useEffect(() => {
    const language = getLangFromQueryStrings() || getLangFromLocalStorage();
    if (language) {
      changeLanguage(language);
    }
  }, [location.search, language]);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    if (!showMobileMenu) {
      document.body.classList.add('scroll-lock');
    } else {
      document.body.classList.remove('scroll-lock');
    }
  };

  const openSubMenu = (index) => {
    setActiveSubMenuLevel1(index);
    setActiveSubMenuLevel2(null);
    setActiveSubMenuLevel3(null);
  };

  const closeSubMenu = () => {
    setActiveSubMenuLevel1(null);
    setActiveSubMenuLevel2(null);
    setActiveSubMenuLevel3(null);
  };

  const openSubSubMenu = (index) => {
    setActiveSubMenuLevel2(index);
    setActiveSubMenuLevel3(null);
  };

  const openSubSubSubMenu = (index) => {
    setActiveSubMenuLevel3(index);
  };

  const changeLanguage = (language) => {
    i18next.changeLanguage(language);
    dispatch(setLanguage(language));
    saveLangToLocalStorage(language);
  };

  return {
    changeLanguage,
    currentLanguageCode: language,
    languages,
    openSubMenu,
    closeSubMenu,
    openSubSubMenu,
    openSubSubSubMenu,
    activeSubMenuLevel1,
    activeSubMenuLevel2,
    activeSubMenuLevel3,
    setActiveSubMenuLevel2,
    setActiveSubMenuLevel3,
    showMobileMenu,
    topNav,
    toggleMobileMenu,
    movePrev
  };
};

export default HeaderLogic;
