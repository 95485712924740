import { useEffect } from 'react';

import clevertap from 'clevertap-web-sdk';

export const useCleverTap = (logLevel = 0) => {
  useEffect(() => {
    const clevertapId = process.env.REACT_APP_CLEVERTAP_ID;
    if (!window.clevertapInitialized) {
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: true });
      clevertap.spa = true;
      clevertap.init(clevertapId, 'sg1');
      window.clevertapInitialized = true;
    }
    clevertap.setLogLevel(logLevel);
  }, [logLevel]);
};

export default useCleverTap;
