import React from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

import './AdvantageContainer.scss';
import rtax from 'assets/images/happy-woman-car-dealership.png';
import { Translate } from 'hooks/Translation';

function AdvantageContainer() {
  const { t } = Translate();

  return (
    <>
      <div className="advantage-container d-flex flex-column flex-lg-row flex-wrap mt-5">
        <div className="advantage col-12 col-sm-12 col-lg-6 col-xl-6 p-sm-0 px-sm-4 pt-sm-3 p-4">
          <div>
            <p>{t('pos_advantage')}</p>
            <div className="bott-bar"></div>
            <h1 className="adv-title-1 bold">
              <span className="subBlueTitle">{t('advantage_title_1')} </span>{' '}
              {t('advantage_title_1_2')}
              <div>{t('advantage_title_1_3')}</div>
            </h1>
            <p className="adv-title-2">{t('advantage_title_3')}</p>
          </div>
          <div className="adv-list d-flex flex-wrap mb-3">
            <div className="d-flex col-lg-6 col-sm-12 col-12">
              <CheckCircleFill className="check m-1" size={20} />
              <p className="medium">{t('advantage_list_1')}</p>
            </div>
            <div className="d-flex col-lg-6 col-sm-12 col-12">
              <CheckCircleFill className="check m-1" size={20} />
              <p className="medium">{t('advantage_list_2')}</p>
            </div>
            <div className="d-flex col-lg-6 col-sm-12 col-12">
              <CheckCircleFill className="check m-1" size={20} />
              <p className="medium">{t('advantage_list_3')}</p>
            </div>
            <div className="d-flex col-lg-6 col-sm-12 col-12">
              <CheckCircleFill className="check m-1" size={20} />
              <p className="medium">{t('advantage_list_4')}</p>
            </div>
          </div>
        </div>
        <div className="rtax-img col-sm-12 col-lg-6 col-xl-6">
          <img src={rtax} alt="road-tax" />
        </div>
      </div>
    </>
  );
}

export default AdvantageContainer;
