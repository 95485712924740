export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  title: {
    fontFamily: 'bold'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #5698FC',
    borderRadius: '5px',
    padding: '16px',
    gap: '12px'
  },
  addDriverTitle: {
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  removeDriver: {
    color: '#5698FC',
    cursor: 'pointer'
  },
  fieldsContainer: {}
};
