import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';

import './inputCalendar.scss';
import { ErrorMessage } from '@hookform/error-message';
import { IconError, IconSuccess } from 'assets/images';

function InputCalendar(state) {
  const [fieldBgColor, setFieldBgColor] = useState('');
  const [fieldIconStatus, setFieldIconStatus] = useState(null);

  const {
    formState: { dirtyFields, errors },
    getValues,
    register
  } = useFormContext({ defaultValues: { [state.register.control]: '' } });

  useEffect(() => {
    if (state.formError || dirtyFields[state.register.control]) {
      if (
        !errors[state.register.control] &&
        Boolean(getValues(state.register.control))
      ) {
        setFieldBgColor('success');
        setFieldIconStatus(IconSuccess);
      } else if (
        Object.keys(errors).length > 0 &&
        errors[state.register.control]
      ) {
        setFieldBgColor('error');
        setFieldIconStatus(IconError);
      } else {
        setFieldBgColor('');
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group
      as={Col}
      lg={state.fieldLg}
      md={state.fieldMg}
      controlId={state.fieldControlId}
      className="form-element-container"
      style={state.style}
    >
      <InputGroup>
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.floatingLabel}
          className="input-calendar"
        >
          {fieldIconStatus && (
            <img
              className="icon calendar-icon"
              src={`${fieldIconStatus}`}
              alt="icon-error"
            />
          )}
          <Form.Control
            type="date"
            max={state.maxYear}
            placeholder={state.fieldPlaceholder}
            defaultValue={state.defaultValue}
            className={`date-wrapper ${fieldBgColor}`}
            {...register(state.register.control, state.register.rules)}
          />
        </FloatingLabel>
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state.register.control}
        render={({ messages }) => {
          return !getValues(state.register.control) ? (
            <span className="error-label">
              {errors[state.register.control].message}
            </span>
          ) : (
            ''
          );
        }}
      />
    </Form.Group>
  );
}

export default InputCalendar;
