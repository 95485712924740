import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from './ReduxHook';
import { setSteps } from 'store/base';

const useSteps = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { stepperRoutes } = useAppSelector((state) => state.base);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingRoute = stepperRoutes?.find((route) => route === currentPath);
    const stepIndex = stepperRoutes?.indexOf(matchingRoute);

    if (stepIndex !== -1) {
      dispatch(setSteps(stepIndex));
    }
  }, [location.pathname, dispatch, stepperRoutes]);
};

export default useSteps;
