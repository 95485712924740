import React from 'react';
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

function InfoPop({ msg }) {
  const popoverClickRootClose = (
    <Popover id="popover-trigger-click-root-close" title="Popover right">
      <div>
        <p>{msg}</p>
      </div>
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger={msg !== '-' ? 'click' : ''}
        rootClose
        placement="auto"
        overlay={popoverClickRootClose}
      >
        <InfoCircle />
      </OverlayTrigger>
    </ButtonToolbar>
  );
}

export default InfoPop;
