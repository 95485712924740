import React from 'react';

import './ProcessContainer.scss';
import DataLayer from 'hooks/DataLayerPush';
import ScrollTo from 'hooks/ScrollTo';
import { Translate } from 'hooks/Translation';
import BrandList from 'pages/Home/SubComponents/BrandList';

function ProcessContainer() {
  const { t } = Translate();
  const { scrollToElement } = ScrollTo();
  const { sendEventData } = DataLayer();
  return (
    <>
      <div className="process-container pt-lg-5 pt-md-5 pt-sm-4 pt-4 px-lg-5 px-md-5 px-sm-4 px-4 mx-auto">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="left-side ">
              <h1 className="bold subBlueTitle">
                <span>{t('claim_process_title')}</span>
              </h1>
              <p>{t('claim_process')}</p>
            </div>
            <div className="brand-list col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
              <BrandList />
            </div>
          </div>
          <div className="right-side col-sm-12 col-md-12 col-lg-5 offset-sm-0 col-md-5 offset-md-0 mx-lg-4 px-lg-5">
            <div className="side-bar col-1 inlineBlk"></div>
            <div className="claim-process col-11 inlineBlk">
              <ol type="1">
                <li className="list-type medium">
                  {t('claim_process_list_1')}
                </li>
                <li className="list-type medium">
                  {t('claim_process_list_2')}
                </li>
                <li className="list-type medium">
                  {t('claim_process_list_3')}
                </li>
                <li className="list-type medium">
                  {t('claim_process_list_4')}
                </li>
              </ol>
            </div>
            <a
              className="inlineBlk medium hover"
              data-event="go_to_page"
              data-event-category="Home"
              data-event-action="Go To Page"
              data-event-label="Need to know more? Read our FAQ"
              onClick={(e) => {
                sendEventData(e);
                scrollToElement('faq');
              }}
            >
              {t('comp_link')}
            </a>
            <div className="brand-list-bottom col-sm-12 col-md-12 col-lg-12 d-lg-none">
              <BrandList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProcessContainer;
