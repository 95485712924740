import React from 'react';

export const faq = [
  {
    title: 'faq_1',
    description: 'faq_ans_1'
  },
  {
    title: 'faq_2',
    description: 'faq_ans_2'
  },
  {
    title: 'faq_3',
    description: 'faq_ans_3'
  },
  {
    title: 'faq_4',
    description: 'faq_ans_4'
  },
  {
    title: 'faq_5',
    description: 'faq_ans_5'
  },
  {
    title: 'faq_6',
    description: 'faq_ans_6'
  },
  {
    title: 'faq_7',
    description: (t) => (
      <>
        {t('faq_ans_7_1')}
        <a href="tel:1-300-300-300">{t('faq_ans_7_2')}</a>
        {t('faq_ans_7_3')}
        <a
          href="https://www.pos.com.my/contact-us#AskPos"
          target="_blank"
          rel="noreferrer"
        >
          {t('faq_ans_7_4')}
        </a>
      </>
    )
  },
  {
    title: 'faq_8',
    description: (t) => (
      <>
        {t('faq_ans_8_1')}
        <a href="tel:1-800-22-5542">{t('faq_ans_8_2')}</a>
        {t('faq_ans_8_3')}
        <a href="1-300-88-6222">{t('faq_ans_8_4')}</a>
        {t('faq_ans_8_5')}
        <a href="1-300-88-6222">{t('faq_ans_8_4')}</a>
        {t('faq_ans_8_7')}
        <a href="1-800-88-5753">{t('faq_ans_8_8')}</a>
        {t('faq_ans_8_9')}
        <a href="03-21803000">{t('faq_ans_8_10')}</a>
        {t('faq_ans_8_11')}
        <a href="1-800-88-6491">{t('faq_ans_8_12')}</a>
      </>
    )
  },
  {
    title: 'faq_9',
    description: 'faq_ans_9'
  },
  {
    title: 'faq_10',
    description: 'faq_ans_10'
  },
  {
    title: 'faq_11',
    description: (t) => (
      <>
        {t('faq_ans_11_13')}
        {t('faq_ans_11_1')}
        <div className="indented_text">
          {t('faq_ans_11_2')}
          <a href="tel:1300-22-5542">{t('faq_ans_11_3')}</a>
          {t('faq_ans_11_4')}
          <a href="mailto: customer.service@allianz.com.my">
            {t('faq_ans_11_5')}
          </a>
        </div>
        {t('faq_ans_11_6')}
        <div className="indented_text">
          {t('faq_ans_11_7')}
          <a href="tel: 1-300-88-6222">{t('faq_ans_11_8')}</a>
          {t('faq_ans_11_9')}
          <a href="tel: 03-2109 6000">{t('faq_ans_11_10')}</a>
          {t('faq_ans_11_11')}
          <a href="mailto: callcentre@zurich.com.my">{t('faq_ans_11_12')}</a>
        </div>
      </>
    )
  },
  {
    title: 'faq_12',
    description: 'faq_ans_12'
  },
  {
    title: 'faq_13',
    description: (t) => (
      <>
        {t('faq_ans_13_1')}{' '}
        <a href="https://www.pidm.gov.my/" target="blank">
          www.pidm.gov.my
        </a>{' '}
        {t('faq_ans_13_2')}
      </>
    )
  }
];
