export const topNav = [
  {
    title: 'Send',
    submenu: [
      {
        title: 'Book A Shipment',
        url: 'https://send.pos.com.my/home',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'Tracking',
        url: 'https://tracking.pos.com.my/tracking',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'Rate Calculator',
        url: 'https://www.pos.com.my/send/ratecalculator',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'SendParcel',
        url: 'https://www.pos.com.my/send/send-parcel',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'Mail',
        submenu: [
          {
            title: 'Domestic',
            submenu: [
              {
                title: 'Prabayar Ekonomi',
                url: 'https://www.pos.com.my/send/mail/domestic/prabayarekonomi',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Standard Mail',
                url: 'https://www.pos.com.my/send/mail/domestic/standard-mail',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Non-standard Mail',
                url: 'https://www.pos.com.my/send/mail/domestic/non-standard-mail',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Registered Mail',
                url: 'https://www.pos.com.my/send/mail/domestic/registered-mail',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Mel Rakyat',
                url: 'https://www.pos.com.my/send/mail/domestic/mel-rakyat',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Postcards',
                url: 'https://www.pos.com.my/send/mail/domestic/postcards',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Others',
                url: 'https://www.pos.com.my/send/mail/domestic/others',
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            ]
          },
          {
            title: 'International',
            submenu: [
              {
                title: 'Air Mail',
                url: 'https://www.pos.com.my/send/mail/international/air-mail',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Surface Mail',
                url: 'https://www.pos.com.my/send/mail/international/surface-mail',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Small Packet',
                url: 'https://www.pos.com.my/send/mail/international/small-packet',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Postcards',
                url: 'https://www.pos.com.my/send/mail/international/postcards',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Add-on Labels',
                url: 'https://www.pos.com.my/send/mail/international/add-on-labels',
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            ]
          }
        ]
      },
      {
        title: 'Pos Laju',
        submenu: [
          {
            title: 'Domestic',
            submenu: [
              {
                title: 'Prepaid',
                url: 'https://www.pos.com.my/send/pos-laju/domestic/prepaid',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Express',
                url: 'https://www.pos.com.my/send/pos-laju/domestic/express',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Economy',
                url: 'https://www.pos.com.my/send/pos-laju/domestic/economy',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Services',
                url: 'https://www.pos.com.my/send/pos-laju/domestic/services',
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            ]
          },
          {
            title: 'International',
            submenu: [
              {
                title: 'Prepaid',
                url: 'https://www.pos.com.my/send/pos-laju/international/prepaid',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Express',
                url: 'https://www.pos.com.my/send/pos-laju/international/express',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Economy',
                url: 'https://www.pos.com.my/send/pos-laju/international/economy',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'Services',
                url: 'https://www.pos.com.my/send/pos-laju/international/services',
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              {
                title: 'EziPoz',
                url: 'https://www.pos.com.my/send/pos-laju/international/ezipoz',
                target: '_blank',
                rel: 'noopener noreferrer'
              }
            ]
          }
        ]
      },
      {
        title: 'Fulfilment Services',
        url: 'https://www.pos.com.my/business-business-submit/pos-malaysia-efs',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'Business Enquiries',
        url: 'https://www.pos.com.my/send/business-enquiries',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'Our Locations',
        url: 'https://www.pos.com.my/pos-outlet-finder',
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    ]
  },
  {
    title: 'Pay',
    url: 'https://www.posonline.com.my/PosOnline.Web.Portal/',
    target: '_blank',
    rel: 'noopener noreferrer'
  },
  {
    title: 'Buy',
    submenu: [
      {
        title: 'Insurance',
        url: 'https://insurance.pos.com.my/',
        target: '_blank',
        rel: 'noopener noreferrer'
      },
      {
        title: 'Shop',
        url: 'https://shop.pos.com.my/',
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    ]
  },
  {
    title: 'Get In Touch',
    url: 'https://www.pos.com.my/contact-us#AskPos',
    target: '_blank',
    rel: 'noopener noreferrer'
  }
];

export const listing = [
  {
    title: 'COURIER',
    id: 101,
    data: [
      {
        id: 1,
        name: 'Track Shipments',
        link: 'https://tracking.pos.com.my/tracking?_ga=2.263545988.902781466.1689770803-1714398369.1677133152'
      },
      {
        id: 2,
        name: 'Book a Shipment',
        link: 'https://send.pos.com.my/home?lg=en&_ga=2.23873970.902781466.1689770803-1714398369.1677133152'
      },
      {
        id: 3,
        name: 'Shipment Rate Calculator',
        link: 'https://www.pos.com.my/send/ratecalculator.html'
      },
      {
        id: 4,
        name: 'SendParcel',
        link: 'https://www.pos.com.my/send/sendparcel.html?_ga=2.92320286.267146719.1658716763-123051764.1658451817'
      },
      {
        id: 5,
        name: 'Fulfilment Services',
        link: 'https://www.pos.com.my/business-business-submit/pos-malaysia-efs.html'
      },
      {
        id: 6,
        name: 'Business Enquiries',
        link: 'https://www.pos.com.my/send/business.html#business_submit'
      },
      {
        id: 7,
        name: 'Pos Laju',
        link: 'https://www.pos.com.my/send/pos-laju.html'
      },
      {
        id: 8,
        name: 'Mail',
        link: 'https://www.pos.com.my/send/mail.html'
      },
      {
        id: 9,
        name: 'Shop',
        link: 'https://www.pos.com.my/shop'
      }
    ]
  },
  {
    title: 'FINANCE',
    id: 102,
    data: [
      {
        id: 1,
        name: 'Pay Bills',
        link: 'https://www.posonline.com.my/PosOnline.Web.Portal/'
      },
      {
        id: 2,
        name: 'Buy Insurance',
        link: 'https://www.posonline.com.my/PosOnline.Web.Portal/Home/LandingpageTakafulInsurance'
      },
      {
        id: 3,
        name: 'JPJ / Road Tax',
        link: 'https://www.pos.com.my/jpj'
      },
      {
        id: 4,
        name: 'Invest in ASNB',
        link: 'https://www.pos.com.my/asnb'
      }
    ]
  },
  {
    title: 'COMPANY',
    id: 103,
    data: [
      {
        id: 1,
        name: 'About Us',
        link: 'https://www.pos.com.my/about-us'
      },
      {
        id: 2,
        name: 'Press Room',
        link: 'https://www.pos.com.my/pressroom_category/categories.html'
      },
      {
        id: 3,
        name: 'Investor Relations',
        link: 'https://www.pos.com.my/investor-relations'
      },
      {
        id: 4,
        name: 'Corporate Governance',
        link: 'https://www.pos.com.my/corporate-governance/'
      },
      {
        id: 5,
        name: 'Sustainability',
        link: 'https://www.pos.com.my/sustainability/'
      },
      {
        id: 6,
        name: 'Legal',
        link: 'https://www.pos.com.my/legal/disclaimer'
      },
      {
        id: 7,
        name: 'Promotions',
        link: 'https://www.pos.com.my/updates/promo.html/'
      },
      {
        id: 8,
        name: 'Service Update',
        link: 'https://www.pos.com.my/news-info/'
      }
    ]
  },
  {
    title: 'HELP',
    id: 104,
    data: [
      {
        id: 1,
        name: 'Contact Us',
        link: 'https://www.pos.com.my/contact-us#AskPos'
      },
      {
        id: 2,
        name: 'FAQs',
        link: 'https://www.pos.com.my/faq/'
      },
      {
        id: 3,
        name: 'Our Locations',
        link: 'https://www.pos.com.my/pos-outlet-finder'
      },
      {
        id: 4,
        name: 'Scam Alerts',
        link: 'https://www.pos.com.my/news-info/scam.html'
      }
    ]
  }
];

export const defaultEnum = {
  mobileLayoutBreakpoint: 450,
  ipadLayoutBreakpoint: 992
};
