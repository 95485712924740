import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';

import './inputStyles.scss';
import { ErrorMessage } from '@hookform/error-message';
import { CheckCircle, IconError, IconSuccess } from 'assets/images';

function InputText(state) {
  const [fieldBgColor, setFieldBgColor] = useState('');
  const [fieldIconStatus, setFieldIconStatus] = useState(null);
  const {
    formState: { dirtyFields, errors },
    getValues,
    register,
    setValue
  } = useFormContext({ defaultValues: { [state.register.control]: '' } });

  useEffect(() => {
    if (state.formError || dirtyFields[state.register.control]) {
      if (
        !errors[state.register.control] &&
        Boolean(getValues(state.register.control))
      ) {
        setFieldBgColor('success');
        setFieldIconStatus(IconSuccess);
      } else if (
        Object.keys(errors).length > 0 &&
        errors[state.register.control]
      ) {
        setFieldBgColor('error');
        setFieldIconStatus(IconError);
      } else {
        setFieldBgColor('');
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group
      as={Col}
      lg={state.fieldLg}
      md={state.fieldMg}
      controlId={state.fieldControlId}
      className="field-wrapper form-element-container"
    >
      <InputGroup>
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.floatingLabel}
        >
          {getValues('mobileNumber') && (
            <span className="countryCode">{state?.countryCode}</span>
          )}
          <Form.Control
            maxLength={state.maxLength}
            readOnly={state.read}
            type={state.fieldType}
            placeholder={state.fieldPlaceholder}
            className={`${fieldBgColor} ${state.countryClass}`}
            value={state.fieldValue}
            disabled={state.disabled}
            defaultValue={state.defaultValue}
            onChange={(e) => {
              let value = e.target.value;
              if (state.register?.rules?.trim) value = value.replace(' ', '');

              if (state.register?.rules?.uppercase) value = value.toUpperCase();
              setValue(state.register?.control, value);
            }}
            onSelect={(e) => {
              let value = e.target.value;
              state.detectEmpty(value);
              if (state.register?.rules?.trim) value = value.replace(' ', '');
              if (state.register?.rules?.no0) {
                if (value.charAt(0) === '0') {
                  value = value.charAt(0).replace('0', '');
                }
              }
              if (state.register?.rules?.uppercase) value = value.toUpperCase();
              setValue(state.register.control, value);
            }}
            {...register(state.register.control, state.register.rules)}
          />
        </FloatingLabel>
        {fieldIconStatus && (
          <img className="icon" src={`${fieldIconStatus}`} alt="icon-error" />
        )}
        {state.validate && (
          <img
            className="icon checkCircleGray"
            src={CheckCircle}
            alt="icon-error"
          />
        )}
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state.register.control}
        render={({ messages }) => {
          return (
            <span className="error-label">
              {errors[state.register.control]?.message}
            </span>
          );
        }}
      />
    </Form.Group>
  );
}

export default InputText;
