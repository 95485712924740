import React from 'react';

import './policyDropdown.scss';
import PolicyDropdownLogic from './Logic/PolicyDropdown.logic';
import { ArrowDown } from 'assets/images';
import { policyTypeLabel } from 'constants/label';

function DropdownButton({ id, label, mobile, name, onClick }) {
  return (
    <button
      id={id}
      name={name}
      onClick={(e) => {
        e.preventDefault();
        onClick(id);
      }}
      style={
        (mobile
          ? { whiteSpace: 'nowrap', fontSize: '13px' }
          : { whiteSpace: 'wrap' },
        {
          display: 'flex',
          alignItems: 'center'
        })
      }
    >
      <div style={{ textAlign: 'left' }}>{label}</div>
      <img src={ArrowDown} alt="#" width={15} className="m-2" />
    </button>
  );
}

function PolicyDropdown({ handleClick }) {
  const {
    confirmVariation,
    isMobileView,
    plans,
    selectedPriceType,
    sumInsuredLabel,
    t,
    vix
  } = PolicyDropdownLogic();

  return (
    <>
      <div className="mx-2 d-flex flex-lg-column title">
        <p className="mb-0">
          {`${confirmVariation?.desc} ${vix?.makeYear} - ${vix?.vehRegNo}`}
        </p>
      </div>
      <div className="policy-dropdown-container d-lg-flex d-block">
        <div className="policy-dropdown d-flex">
          <DropdownButton
            id="policy"
            name="policy"
            label={`${t('p_type')}: ${t(policyTypeLabel[plans.coverType])}`}
            onClick={handleClick}
            mobile={isMobileView}
          />
          <DropdownButton
            id="sumInsured"
            name="sumInsured"
            label={sumInsuredLabel}
            onClick={handleClick}
            mobile={isMobileView}
          />
          <DropdownButton
            id="sortPrice"
            name="sortPrice"
            label={selectedPriceType}
            onClick={handleClick}
            mobile={isMobileView}
          />
        </div>
      </div>
    </>
  );
}

export default PolicyDropdown;
