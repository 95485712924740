import { createSelector } from '@reduxjs/toolkit';

import { addOnsList } from 'constants/label';
import { calculateNewDates, convertDate } from 'utils/helper';

const vix = (state) => state.vix;
const vhlType = (state) => state.vhlType;
const vehicleForm = (state) => state.vehicleForm;
const plans = (state) => state.plans;
const premium = (state) => state.premium;
const base = (state) => state.base;
const addOns = (state) => state.addOns;
const checkout = (state) => state.checkout;
const coverOption = (state) => state.coverOption;

const url = process.env.REACT_APP_BASEURL;

export const premiumRequest = createSelector(
  [base, vehicleForm, vix, vhlType, plans, addOns, coverOption],
  (base, vehicleForm, vix, vhlType, plans, addOns, coverOption) => {
    const { confirmVariation, vix: vixData } = vix;
    const { effectiveDate, effectiveTime, expiryDate } = calculateNewDates();
    const { coverType, planData, selectedData, selectedPlan } = plans;
    const { addOnData } = addOns;
    const { selectSumInsured, sumInsured } = base;
    const { additionalDrivers } = coverOption;
    return {
      address1: vehicleForm.address || 'DEFAULT',
      address2: vehicleForm.city,
      address3: vehicleForm.state,
      name: vehicleForm?.fullName,
      email: vehicleForm?.email,
      hPhoneNo: vehicleForm?.mobileNumber && `0${vehicleForm?.mobileNumber}`,
      actualMarketValue: selectedData?.marketValue,
      actualSumInsured: +sumInsured || +confirmVariation?.sumInsured,
      compCode: selectedPlan || 'POS',
      coverCode: coverType || '01',
      agentCode: 'POS',
      newIc: vehicleForm?.newIc,
      passportNo: vehicleForm?.passportNo,
      channel: 'POS',
      gender: vehicleForm?.gender,
      birthDate: vehicleForm?.birthDate,
      postCode: vehicleForm?.postcode,
      maritalStatus: vehicleForm?.maritalStatus,
      makeYear: vixData?.makeYear,
      regNo: vehicleForm?.regNo,
      chassisNo: vixData?.chassisNo,
      sumInsured: +sumInsured || +confirmVariation?.sumInsured,
      engineNo: vixData?.engineNo,
      makeCodeMajor: vixData?.ismMakeCode,
      makeCodeMinor: `${confirmVariation?.model}*${confirmVariation?.major}`,
      vehCapacity: vixData?.vehCapaCity,
      requestId: vixData?.requestId,
      keepFlag: 'Y',
      vehCapacityCode: 'CC',
      seatCapacity: vhlType.type === 'motorcycle' ? '2' : '5',
      vehTypeCode: vixData?.vehTypeCode,
      effectiveDate,
      effectiveTime,
      expiryDate,
      piamDrv: vhlType.type === 'motorcycle' ? '05' : '03',
      ncdPerc: vixData?.ncdPerc,
      ownershipType: 'I',
      useCode: vixData?.veHuseCode,
      addBenData: {
        item: planData?.[coverType]?.[selectSumInsured]?.[selectedPlan]
          ? benefitListDecorator(
              planData[coverType][selectSumInsured][selectedPlan]?.filter(
                (addOn) => addOnData[selectedPlan]?.includes(addOn.benCode)
              )
            )
          : []
      },
      addDrvData: {
        item: additionalDrivers || []
      }
    };
  }
);

export const addOnsRequest = createSelector([plans], (plans) => {
  return {
    coverCode: plans?.coverType,
    compCode: plans?.selectedPlan,
    addBenData: { item: addOnsList }
  };
});

export const finalInsuranceRequest = createSelector(
  [premium, vix, plans, vehicleForm, base, vhlType, addOns],
  (premium, vix, plans, vehicleForm, base, vhlType, addOns) => {
    const { confirmVariation, vix: vehicleData } = vix;
    const { coverType, planData, selectedData, selectedPlan } = plans;
    const { selectSumInsured, sumInsured } = base;
    const { effectiveDate, effectiveTime, expiryDate } = calculateNewDates();
    return {
      compCode: selectedPlan,
      actPrem: selectedData?.actPrem,
      address1: vehicleForm.address || 'DEFAULT',
      address2: vehicleForm.city,
      agentCode: 'POS',
      flquoteNo: selectedData?.flQuoteNo,
      coverCode: coverType,
      address3: vehicleForm.state,
      antitd: selectedData?.antitd,
      name: vehicleForm?.fullName,
      bankAppCode: '',
      ccardExpdt: '',
      ccardType: '',
      newIc: vehicleForm?.newIc,
      passportNo: vehicleForm?.passportNo,
      channel: 'POS',
      claimAmt: selectedData?.claimAmt,
      cnCondition: 'n',
      cnPaystatus: 'c',
      commGstAmt: selectedData?.commGstAmt,
      commiamt: selectedData?.commiamt,
      commiPerc: selectedData?.commiPerc,
      discountAmt: selectedData?.discountAmt,
      discountPerc: selectedData?.discountPerc,
      driveExp: selectedData?.driveExp,
      email: vehicleForm?.email,
      excess: selectedData?.excess,
      garage: selectedData?.garage,
      grossDue: selectedData?.grossDue,
      grossDue2: selectedData?.grossDue2,
      grossPrem: selectedData?.grossPrem,
      gstAmt: selectedData?.gstAmt,
      gstClaimPerc: selectedData?.gstClaimPerc,
      hPhoneNo: vehicleForm?.mobileNumber && `0${vehicleForm?.mobileNumber}`,
      insertStmp: selectedData?.insertStmp,
      loadingAmt: selectedData?.loadingAmt,
      loadingPerc: selectedData?.loadingPerc,
      ncdAmt: selectedData?.ncdAmt,
      netDue: selectedData?.netDue,
      netDue2: selectedData?.netDue2,
      netPrem: selectedData?.netPrem,
      occupMajor: selectedData?.occupMajor,
      payAmt: 0,
      payType: '',
      preInsNcd: selectedData?.preInsNcd,
      preinsRegNo: selectedData?.preinsRegNo,
      pScoreOriLoading: 0.0,
      purchasePrice: selectedData?.purchasePrice,
      purpose: selectedData?.purpose,
      serviceTaxAmt: selectedData?.serviceTaxAmt,
      serviceTaxPerc: selectedData?.serviceTaxPerc,
      stampDuty: selectedData?.stampDuty,
      stateCode: selectedData?.stateCode.toUpperCase(),
      tariffPremium: selectedData?.tariffPremium,
      theftClaim: selectedData?.theftClaim,
      thirdClaim: selectedData?.thirdClaim,
      townDesc: selectedData?.townDesc,
      variantName: confirmVariation?.desc,
      vehBody: selectedData?.vehBody,
      vehBodyCode: selectedData?.vehBodyCode,
      vehClaim: selectedData?.vehClaim,
      gender: vehicleForm?.gender,
      birthDate: vehicleForm?.birthDate,
      postCode: vehicleForm?.postcode,
      maritalStatus: vehicleForm?.maritalStatus,
      makeYear: vehicleData?.makeYear,
      regNo: vehicleForm?.regNo,
      chassisNo: vehicleData?.chassisNo,
      sumInsured: +sumInsured || +confirmVariation?.sumInsured,
      engineNo: vehicleData?.engineNo,
      makeCodeMajor: vehicleData?.ismMakeCode,
      makeCodeMinor: vehicleData?.makeCodeMinor,
      vehCapacity: vehicleData?.vehCapaCity,
      vehCapacityCode: 'CC',
      seatCapacity: vhlType !== 'car' ? '2' : '5',
      vehTypeCode: vehicleData?.vehTypeCode,
      effectiveDate,
      effectiveTime,
      expiryDate,
      piamDrv: vhlType === 'car' ? '03' : '05',
      ncdPerc: selectedData?.ncdPerc,
      ownershipType: 'I',
      oldExpiryDate: convertDate(vehicleData?.expiryDate),
      useCode: vehicleData?.veHuseCode,
      benefit: planData?.[coverType]?.[selectSumInsured]?.[selectedPlan].filter(
        (addOnsList) =>
          addOns.addOnData?.[selectedPlan]?.includes(addOnsList.benCode)
      ),
      addon: selectedData?.addDrvData?.item,
      drvAge: vehicleForm?.age,
      drvGender: vehicleForm?.gender,
      posStaffDisc: base.referralCode
    };
  }
);

export const initPaymentReq = createSelector(
  [vehicleForm, checkout],
  (vehicleForm, checkout) => {
    const { insurance } = checkout;
    return {
      payment_gateway: 'kiplepay',
      application_name: 'samurai',
      return_url: `${process.env.REACT_APP_PGW_URL}process_payment`,
      redirect_success_url: `${url}/payment-status?status=success`,
      redirect_failure_url: `${url}/payment-status?status=failed`,
      redirect_abort_url: vehicleForm?.regNo,
      order_total_amount: `${insurance?.grossDue2 || insurance?.grossDue}`,
      order_gst_amount: '0',
      order_ship_name: vehicleForm?.fullName,
      order_ship_country: 'Malaysia',
      order_telephone: `+60${vehicleForm?.mobileNumber}`,
      order_email: vehicleForm?.email,
      order_delivery_charges: '0',
      order_service_charges: '0',
      order_reference: insurance?.id,
      generate_html_form: 'true',
      dynamic_callback_url: `${process.env.REACT_APP_AWS_URL}payment/callback-url`
    };
  }
);

export const vixNcd = (vixData) => {
  return {
    regNo: vixData?.regNo,
    newIc: vixData?.newIc,
    passportNo: vixData?.passport,
    postcode: vixData?.postcode
  };
};

export const benefitListDecorator = (benefitList) => {
  return (
    benefitList?.map((benefit) => {
      if (benefit?.benefitCode === 'WSC' || benefit?.benCode === 'WSC') {
        return {
          benCode: benefit?.benefitCode || benefit.benCode,
          benDesc: benefit?.benefitTitle || benefit.benDesc,
          sumInsured: benefit?.sumInsured || 500
        };
      }
      return {
        benCode: benefit?.benefitCode || benefit.benCode,
        benDesc: benefit?.benefitTitle || benefit.benDesc
      };
    }) || null
  );
};
