import React, { useEffect, useState } from 'react';

import { setIsUpdate } from 'components/CoverageOption/Logic/CoverageOption.reducer';
import { MainContainer } from 'components/UIComponent';
import CryptoJS from 'crypto-js';
import useCleverTapPush from 'hooks/CleverTapData';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';

function PaymentStatus() {
  const queryParameters = new URLSearchParams(window.location.search);
  const status = queryParameters.get('status');
  const { insurance, vehicleForm } = useAppSelector((state) => {
    return {
      insurance: state.checkout.insurance,
      vehicleForm: state.vehicleForm
    };
  });

  const [paramsData, setParamsData] = useState();
  const { currentRoute, moveToPage, redirectTo } = useStepper();
  console.log(currentRoute);
  const { pushData } = useCleverTapPush('/payment-status');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsUpdate(true));
    setParamsData(encodeURIComponent(encryptData(vehicleForm)));
    if (status === 'success') {
      pushData();
    }
  }, []);

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_SECRET_KEY
    ).toString();
  };

  const retry = () => {
    dispatch(setIsUpdate(false));
    moveToPage('/checkout');
  };

  return (
    <MainContainer
      isLoading={status}
      modalProps={{
        type: status
      }}
      refId={insurance?.id}
      handleClose={
        status === 'success'
          ? () =>
              redirectTo(
                `${process.env.REACT_APP_ROADTAX_URL}?status=${status}&data=${paramsData}`
              )
          : () => retry()
      }
    />
  );
}

export default PaymentStatus;
