/** @jsxImportSource theme-ui */
import React from 'react';

import SumInsuredOptionLogic from './Logic/SumInsuredOption.logic';
import { style } from './SumInsuredOption.style';
import { InfoIcon } from 'assets/images';
import { ButtonUi, RadioCheck } from 'components/UIComponent';
import { useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { formatNumber } from 'utils/helper';

function SumInsuredOption() {
  const { t } = Translate();
  const { confirmVariation } = useAppSelector((state) => state.vix);
  const { handleClick, selectSumInsured, sumInsuredOption } =
    SumInsuredOptionLogic();
  const date = new Date().toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  const renderRadioOption = (
    value,
    id,
    isActiveCheck,
    handleClickFn,
    label
  ) => (
    <div
      name={id}
      sx={style.radioCard(isActiveCheck)}
      onClick={() => handleClickFn(id, value)}
    >
      <RadioCheck active={isActiveCheck} handleClick={handleClickFn} id={id} />
      <div>
        <label htmlFor={id}>
          <span>RM {formatNumber(value)}</span>
        </label>
        <div sx={style.label}>{t(label)}</div>
      </div>
    </div>
  );

  return (
    <div sx={style.container}>
      <div sx={style.information}>
        {`${t('as_of')} ${date}, ${t('as_of_2')} ${
          confirmVariation?.sumInsured
        }`}
        <div sx={style.title}>{t('confirm_coverage')}</div>
      </div>
      <div sx={style.radioContainer}>
        <div>
          {renderRadioOption(
            sumInsuredOption.sumInsured,
            'sumInsured',
            selectSumInsured === 'sumInsured',
            handleClick,
            'recommended_coverage'
          )}
        </div>
        <div>
          {renderRadioOption(
            sumInsuredOption.reducedSumInsured,
            'reducedSumInsured',
            selectSumInsured === 'reducedSumInsured',
            handleClick,
            'lowest_coverage'
          )}
        </div>
      </div>
      <div sx={style.footer}>
        <img src={InfoIcon} alt="info-icon" />
        <div>
          <div sx={style.footerInfo}>{t('market_value')}</div>
          <div sx={style.label}>{t('market_value_info')}</div>
        </div>
      </div>
      <ButtonUi label={'confirm'} />
    </div>
  );
}

export default SumInsuredOption;
