/** @jsxImportSource theme-ui */
import React from 'react';
import { Check2Circle, ExclamationCircle } from 'react-bootstrap-icons';

import { style } from '../StatusModal.style';
import { errorCodeMessages } from 'constants/label';
import { Translate } from 'hooks/Translation';

const StatusModalLogic = ({ code, refId, type }) => {
  const { t } = Translate();
  const statusData = {
    success: {
      Icon: <Check2Circle color={'#1FC74E'} size={60} />,
      bgColor: '#D1FADF',
      title: 'fl_error_success',
      message: (
        <>
          <div>{t('payment_msg_1')}</div>
          <div>{t('payment_msg_2')}</div>
          <div>{t('payment_msg_3')}</div>
        </>
      ),
      footNote: (
        <>
          <div>{t('your_id')}</div>
          <div sx={style.footNote}>{refId}</div>
        </>
      )
    },
    error: {
      Icon: <ExclamationCircle color={'#A94442'} size={60} />,
      bgColor: '#F2DEDE',
      title: 'fl_error_sorry'
    },
    failed: {
      Icon: <ExclamationCircle color={'#A94442'} size={60} />,
      bgColor: '#F2DEDE',
      title: 'fl_error_sorry',
      message: t('all_error_req')
    }
  };

  const buttonLabel = {
    success: 'Done',
    failed: 'fl_error_retry'
  };

  return {
    Icon: statusData?.[type]?.Icon,
    bgColor: statusData?.[type]?.bgColor,
    t,
    message:
      statusData?.[type]?.message ||
      t(errorCodeMessages?.[code]) ||
      t('error4'),
    title: statusData?.[type]?.title,
    btnLabel: buttonLabel?.[type] || 'fl_error_close',
    footNote: statusData?.[type]?.footNote
  };
};

export default StatusModalLogic;
