import React from 'react';

import { VhlTypeContainerLogic } from './Logic/VhlTypeContainer.logic';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { CarTypeContainer } from 'components';
import { VehicleForm } from 'pages';

function VhlTypeContainer() {
  const [parent] = useAutoAnimate();
  const { closeForm, openUserForm, scrollToUserForm, t } =
    VhlTypeContainerLogic();
  return (
    <div id="vhl" className="container vhlContainerCollapse" ref={parent}>
      <div className="vhlBg row VhlTypeContainer pb-5 pt-lg-5 pt-md-5 pt-sm-5 pt-xs-1 px-3 px-sm-4 px-md-5 px-lg-5">
        <div className="col-xl-7 col-lg-8 col-md-6 col-sm-12 col-xs-12">
          <h1 className="mainTitle bold">
            {t('home_title')}
            <span className="hl">{t('home_title_2')}</span>
          </h1>
          <div className="medium pt-2">{t('home_caption_1')}</div>
        </div>
        <div className="rigt-vhcl-container col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-sm-5 mt-5 mt-md-0">
          <h1 className="subTitle mb-4 medium" style={{ color: '#5698FC' }}>
            {t('home_title_3')}
          </h1>
          <CarTypeContainer
            color={'lightBlue'}
            type={'car'}
            nextClick={openUserForm ? closeForm : scrollToUserForm}
          />
          <CarTypeContainer
            color={'lightBlue'}
            type={'motorcycle'}
            nextClick={openUserForm ? closeForm : scrollToUserForm}
          />
        </div>
      </div>
      <VehicleForm isOpen={openUserForm} />
    </div>
  );
}

export default VhlTypeContainer;
