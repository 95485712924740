/** @jsxImportSource theme-ui */
import React from 'react';

import { style } from './ModalContainer.style';
import { Modal } from 'components/UIComponent';

const ModalContainer = ({ children, handleHide, show }) => {
  return (
    <Modal centered show={show} onHide={handleHide}>
      <div sx={style.container}>{children}</div>
    </Modal>
  );
};

// ModalContainer.Header = ({ children }) => (
//   <Modal.Header
//     style={{
//       display: 'flex',
//       justifyContent: 'center',
//       margin: 0,
//       padding: 0,
//       gap: '10px'
//     }}
//   >
//     {children}
//   </Modal.Header>
// );

// ModalContainer.Title = ({ children }) => (
//   <Modal.Title
//     style={{
//       fontFamily: 'Gotham-medium',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       marginBottom: '12px',
//       padding: 0,
//       fontSize: '28px',
//       fontWeight: 'bold'
//     }}
//   >
//     {typeof children === 'string' ? <h1>{children}</h1> : children}
//   </Modal.Title>
// );

// ModalContainer.Body = ({ children }) => (
//   <Modal.Body
//     style={{
//       display: 'flex',
//       justifyContent: 'center',
//       margin: 0,
//       padding: 0,
//       flexDirection: 'column'
//     }}
//   >
//     {children}
//   </Modal.Body>
// );

// ModalContainer.Footer = ({ children }) => (
//   <Modal.Footer
//     style={{
//       justifyContent: 'center',
//       textAlign: 'center',
//       margin: 0,
//       padding: 0
//     }}
//   >
//     {children}
//   </Modal.Footer>
// );

// ModalContainer.Header.displayName = 'ModalContainer.Header';
// ModalContainer.Body.displayName = 'ModalContainer.Body';
// ModalContainer.Footer.displayName = 'ModalContainer.Footer';
// ModalContainer.Title.displayName = 'ModalContainer.Title';

export default ModalContainer;
