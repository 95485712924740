import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const useGoogleTagManager = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    if (!window.tagManagerInitialized) {
      TagManager.initialize(tagManagerArgs);
      window.tagManagerInitialized = true;
    }
  }, []);
};

export default useGoogleTagManager;
