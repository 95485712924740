import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setUpdateData } from 'components/Plans/Logic/Plans.reducer';
import { setIsLoading } from 'store/base';
import { getCookie } from 'utils/cookieStorage';

const authBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { endpoint }) => {
    const token = getCookie('api_token');
    headers.set('Authorization', `Bearer ${token}`);
    headers.set('X-Api-Key', process.env.REACT_APP_AUTH_KEY);
    headers.set('Content-Type', 'application/json');
    return headers;
  }
});

export const Services = createApi({
  reducerPath: 'Services',
  baseQuery: authBaseQuery,
  endpoints: (builder) => ({
    checkVehicle: builder.mutation({
      query: (data) => ({
        url: '/vixncd',
        method: 'POST',
        body: data
      })
    }),
    getPremium: builder.mutation({
      query: (data) => ({
        url: '/premium',
        method: 'POST',
        body: data
      })
    }),
    updateCoverageAddOn: builder.mutation({
      queryFn: async (arg, { dispatch }, extraOptions, baseQuery) => {
        dispatch(setIsLoading(true));
        const { selectSumInsured, ...rest } = arg;
        const { compCode, coverCode } = rest;
        const resp = await baseQuery({
          url: '/premium',
          method: 'POST',
          body: rest
        });
        if (resp.error) {
          console.log(resp.error);
        }
        const {
          data: {
            data: { item }
          }
        } = resp;
        dispatch(
          setUpdateData({
            coverType: coverCode,
            selectedPlan: compCode,
            selectSumInsured,
            value: item[0].addBenData.item[0],
            key: '0'
          })
        );
        dispatch(setIsLoading(false));
      }
    }),
    updateDriverAddOn: builder.mutation({
      queryFn: async (arg, { dispatch }, extraOptions, baseQuery) => {
        // dispatch(setIsLoading(true));
        const { selectSumInsured, ...rest } = arg;
        const { compCode, coverCode } = rest;
        const resp = await baseQuery({
          url: '/premium',
          method: 'POST',
          body: rest
        });
        if (resp.error) {
          console.log(resp.error);
        }
        const {
          data: {
            data: { item }
          }
        } = resp;
        if (item[0].addBenData.item[0].benPremium === 0) {
          dispatch(
            setUpdateData({
              coverType: coverCode,
              selectedPlan: compCode,
              selectSumInsured,
              value: item[0].addBenData.item[0],
              key: '3'
            })
          );
        }
      }
    }),
    getPlan: builder.mutation({
      queryFn: async (arg, { dispatch }, extraOptions, baseQuery) => {
        try {
          const data = await baseQuery({
            url: '/premium',
            method: 'POST',
            body: arg
          }).unwrap();
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      }
    }),
    finalPlan: builder.mutation({
      query: (data) => ({
        url: '/insurance',
        method: 'POST',
        body: data
      })
    }),
    paymentGateway: builder.mutation({
      query: (data) => ({
        url: `${process.env.REACT_APP_AWS_URL}payment/submit-to-pgw`,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  useCheckVehicleMutation,
  useFinalPlanMutation,
  useGetPlanMutation,
  useGetPremiumMutation,
  usePaymentGatewayMutation,
  useUpdateCoverageAddOnMutation,
  useUpdateDriverAddOnMutation
} = Services;
