import React from 'react';

export const useLocalStorage = () => {
  const saveValueToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const getValueFromLocalStorage = (key) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return undefined;
    }
  };
  const clearLocalStorage = () => {
    localStorage.clear();
  };
  const deleteValueFromLocalStorage = (key, value) => {
    localStorage.removeItem(key[value]);
  };

  return {
    clearLocalStorage,
    deleteValueFromLocalStorage,
    getValueFromLocalStorage,
    saveValueToLocalStorage
  };
};

export default useLocalStorage;
