import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  sumInsured: 500,
  message: undefined,
  isUpdate: undefined,
  additionalDrivers: undefined
};

export const coverageOption = createSlice({
  name: 'coverageOption',
  initialState,
  reducers: {
    setCoverageUpdate: (state, action) => {
      state.sumInsured = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
    setAdditionalDrivers: (state, action) => {
      state.additionalDrivers = action.payload;
    },
    resetVehicleState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const {
  setAdditionalDrivers,
  setCoverageUpdate,
  setIsUpdate,
  setMessage
} = coverageOption.actions;

export default coverageOption.reducer;
