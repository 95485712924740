import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  insurance: undefined,
  payment: undefined,
  totalPrice: undefined
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    insuranceData: (state, action) => {
      state.insurance = action.payload;
    },
    paymentData: (state, action) => {
      state.payment = action.payload;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    resetVehicleState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { insuranceData, paymentData, setTotalPrice } =
  checkoutSlice.actions;

export default checkoutSlice.reducer;
