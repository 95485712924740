import React, { useEffect, useState } from 'react';

import {
  AdditionalDrivers,
  CoverageOption,
  PolicyTypeModal,
  PriceSortModal,
  StatusModal,
  SumInsuredOption,
  VehicleLoading
} from 'components';
import { useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';

const MainContainerLogic = ({
  code,
  handleClose,
  isLoading,
  refId,
  type,
  updateType
}) => {
  const { t } = Translate();
  const { redirectTo } = useStepper();
  const { isUpdate, showModal } = useAppSelector((state) => {
    return {
      showModal: state.modal.showModal,
      isUpdate: state.coverOption.isUpdate
    };
  });

  const childrenRender = {
    api: <VehicleLoading />,
    error: <StatusModal type={type} handleClick={handleClose} code={code} />,
    updateCoverage: <CoverageOption />,
    policy: <PolicyTypeModal />,
    sumInsured: <SumInsuredOption />,
    sortPrice: <PriceSortModal />,
    additionalDrivers: <AdditionalDrivers />,
    success: (
      <StatusModal type={type} refId={refId} handleClick={handleClose} />
    ),
    failed: (
      <StatusModal
        type={type}
        twoButtons={{
          value: true,
          text: t('Close'),
          handleClick: () => redirectTo('https://www.pos.com.my/')
        }}
        handleClick={handleClose}
      />
    )
  };

  return {
    childrenRender: childrenRender?.[type || updateType],
    isUpdate,
    show: isLoading || showModal,
    close: handleClose
  };
};

export default MainContainerLogic;
