import React from 'react';

import './Toggle.scss';

function InputToggle({ check, label, toggle }) {
  return (
    <>
      <div className="toggleComponent">
        {label && <span className="m-3 label">{label}</span>}
        <label className="switch">
          <input type="checkbox" onChange={() => toggle()} checked={check} />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
}

export default InputToggle;
