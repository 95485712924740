import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Header.style.scss';
import Logo from '../Logo';
import HeaderLogic from './Logic/Header.logic';
import DataLayer from 'hooks/DataLayerPush';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';

const Language = ({ sendEventData }) => {
  const { changeLanguage, currentLanguageCode, languages } = HeaderLogic();

  return (
    <>
      <div
        className="d-flex flex-row-reverse collapse navbar-collapse"
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav d-flex flex-row">
          {languages.map(({ code, country_code: countryCode, name }, index) => (
            <li key={countryCode}>
              <button
                disabled={code === currentLanguageCode}
                className={`btn btn-link ${
                  code === currentLanguageCode ? 'text-muted' : 'text-danger'
                } noLinkLine`}
                aria-current="page"
                onClick={(e) => {
                  sendEventData(e);
                  changeLanguage(code);
                }}
                data-event={'select_language'}
                data-event-category={'Menu'}
                data-event-action={'Select Language'}
                data-event-label={code === 'en' ? 'English' : 'Bahasa'}
              >
                {name}
              </button>
              <span>{index === 0 ? '/' : ''}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const HeaderMobile = ({
  sendEventData,
  showMobileMenu,
  toggleMobileMenu,
  topNav
}) => {
  const {
    activeSubMenuLevel1,
    activeSubMenuLevel2,
    activeSubMenuLevel3,
    closeSubMenu,
    openSubMenu,
    openSubSubMenu,
    openSubSubSubMenu,
    setActiveSubMenuLevel2,
    setActiveSubMenuLevel3
  } = HeaderLogic();
  const navigate = useNavigate();
  return (
    <div>
      <div
        id="mySidenav"
        className={`megamenu-t mm-listview sidenav slideAnim ${
          showMobileMenu ? 'open' : ''
        }`}
      >
        <div>
          <div
            className="mlogo"
            onClick={(e) => {
              sendEventData(e);
              navigate('/');
            }}
            data-event="go_to_home"
            data-event-category="Menu"
            data-event-action="Go To Home"
            data-event-label="Home"
          >
            <Logo width={53} height={31} />
          </div>
          <div className="mm-navbar">
            <button
              className="closebtn m-top-1 close-mob-menu icon-remove"
              onClick={toggleMobileMenu}
              aria-label="Close mobile menu"
            >
              Close
            </button>
          </div>
        </div>
        {topNav.map((navItem, index) => (
          <div key={index} className="level-top mm-listitem__text">
            <a
              className="mm-listitem"
              href={navItem.url}
              target={navItem.target}
              rel={navItem.rel}
              data-event="top_menu"
              data-event-category="Menu"
              data-event-action="Click Top Menu"
              data-event-label={navItem.title}
              onClick={(e) => {
                if (navItem.submenu) {
                  sendEventData(e);
                  openSubMenu(index);
                } else {
                  navigate('/');
                }
              }}
            >
              <span>{navItem.title}</span>
              {navItem.submenu && (
                <button
                  className="mm-btn angle-right mm-listitem__btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    openSubMenu(index);
                  }}
                ></button>
              )}
            </a>

            {activeSubMenuLevel1 === index && navItem.submenu && (
              <div className="megamenu-t mm-listview sidenav">
                <button
                  className=" mm-btn backbtn mm-listitem__btn"
                  onClick={closeSubMenu}
                >
                  <span className="visually-hidden">close</span>
                </button>

                {navItem.submenu.map((subNavItem, subIndex) => (
                  <div className="level-top mm-listitem__text" key={subIndex}>
                    <a
                      className="mm-listitem"
                      href={subNavItem.url}
                      target={subNavItem.target}
                      rel={subNavItem.rel}
                      data-event="top_menu"
                      data-event-category="Menu"
                      data-event-action="Click Top Menu"
                      data-event-label={`${navItem.title} - ${subNavItem.title}`}
                      onClick={(e) => {
                        if (subNavItem.submenu) {
                          sendEventData(e);
                          openSubSubMenu(subIndex);
                        } else {
                          navigate('/');
                        }
                      }}
                    >
                      <span>{subNavItem.title}</span>
                      {subNavItem.submenu && (
                        <button
                          className="mm-btn angle-right mm-listitem__btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            openSubSubMenu(subIndex);
                          }}
                        ></button>
                      )}
                    </a>

                    {activeSubMenuLevel2 === subIndex && subNavItem.submenu && (
                      <div className="megamenu-t mm-listview sidenav">
                        <button
                          className=" mm-btn backbtn mm-listitem__btn"
                          onClick={() => setActiveSubMenuLevel2(null)}
                        ></button>
                        {subNavItem.submenu.map(
                          (subSubNavItem, subSubIndex) => (
                            <div
                              className="level-top mm-listitem__text"
                              key={subSubIndex}
                            >
                              <a
                                className="mm-listitem"
                                key={subSubIndex}
                                href={subSubNavItem.url}
                                target={subSubNavItem.target}
                                rel={subSubNavItem.rel}
                                data-event="top_menu"
                                data-event-category="Menu"
                                data-event-action="Click Top Menu"
                                data-event-label={`${navItem.title} - ${subNavItem.title} - ${subSubNavItem.title}`}
                                onClick={(e) => {
                                  if (subSubNavItem.submenu) {
                                    sendEventData(e);
                                    openSubSubSubMenu(subSubIndex);
                                  } else {
                                    navigate('/');
                                  }
                                }}
                              >
                                <span>{subSubNavItem.title}</span>
                                {subSubNavItem.submenu && (
                                  <button
                                    className="mm-btn angle-right mm-listitem__btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openSubSubSubMenu(subSubIndex);
                                    }}
                                  ></button>
                                )}
                              </a>

                              {activeSubMenuLevel3 === subSubIndex &&
                                subSubNavItem.submenu && (
                                  <div className="megamenu-t mm-listview sidenav">
                                    <button
                                      className=" mm-btn backbtn mm-listitem__btn"
                                      onClick={() =>
                                        setActiveSubMenuLevel3(null)
                                      }
                                    ></button>
                                    {subSubNavItem.submenu.map(
                                      (subSubSubNavItem, subSubSubIndex) => (
                                        <div
                                          className="level-top mm-listitem__text"
                                          key={subSubSubIndex}
                                        >
                                          <a
                                            className="mm-listitem"
                                            key={subSubSubIndex}
                                            href={subSubSubNavItem.url}
                                            target={subSubSubNavItem.target}
                                            rel={subSubNavItem.rel}
                                          >
                                            {subSubSubNavItem.title}
                                          </a>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function Header() {
  const { movePrev, showMobileMenu, toggleMobileMenu, topNav } = HeaderLogic();
  const { sendEventData } = DataLayer();
  const { redirectTo } = useStepper();
  const { t } = Translate();

  return (
    <>
      <div className="headerContainer">
        <nav className={`topnav ${showMobileMenu ? 'hide-topnav' : ''}`}>
          <ul className="left-nav">
            <div
              className="logo"
              onClick={(e) => {
                sendEventData(e);
                redirectTo('https://www.pos.com.my');
              }}
              data-event="go_to_home"
              data-event-category="Menu"
              data-event-action="Go To Home"
              data-event-label="Home"
            >
              <Logo width={53} height={31} />
            </div>
            <li>
              <div className="sections nav-sections">
                <div className="section-items nav-sections-items">
                  <div
                    className="section-item-content-t nav-sections-item-content"
                    id="store.menu"
                    data-role="content"
                  >
                    <nav
                      className="navigation"
                      data-action="navigation"
                      id="mobileNav"
                    >
                      <ul className="megamenu-t">
                        {topNav.map((mainNav, i) => (
                          <li
                            key={i}
                            className={`level0 nav-${
                              i + 1
                            } category-item ui-menu-item-t level-top ${
                              mainNav.submenu ? 'parent' : ''
                            } ${i === 0 ? 'first' : ''} ${
                              i === topNav.length - 1 ? 'last' : ''
                            }`}
                          >
                            {mainNav.submenu && (
                              <i className="arr-menu-opener"></i>
                            )}
                            <a
                              className={`level-top ${
                                mainNav.submenu ? 'has-submenu' : ''
                              }`}
                              href={mainNav.url}
                              target={mainNav.target}
                              rel={mainNav.rel}
                              data-event="top_menu"
                              data-event-category="Menu"
                              data-event-action="Click Top Menu"
                              data-event-label={mainNav.title}
                              onClick={sendEventData}
                            >
                              <span>{mainNav.title}</span>
                            </a>
                            {mainNav.submenu && (
                              <ul className="level0 submenu-t ui-menu-t">
                                {mainNav.submenu.map((subNav, j) => (
                                  <li
                                    key={j}
                                    className={`level1 nav-${i + 1}-${
                                      j + 1
                                    } category-item ui-menu-item-t ${
                                      j === 0 ? 'first' : ''
                                    } ${
                                      j === mainNav.submenu.length - 1
                                        ? 'last'
                                        : ''
                                    } ${subNav.submenu ? 'parent' : ''}`}
                                  >
                                    {subNav.submenu && (
                                      <i className="arr-menu-opener"></i>
                                    )}
                                    <a
                                      className={`submenu-title ${
                                        subNav.submenu ? 'has-submenu' : ''
                                      }`}
                                      href={subNav.url}
                                      target={subNav.target}
                                      rel={subNav.rel}
                                      data-event="top_menu"
                                      data-event-category="Menu"
                                      data-event-action="Click Top Menu"
                                      data-event-label={`${mainNav.title} - ${subNav.title}`}
                                      onClick={sendEventData}
                                    >
                                      <span>{subNav.title}</span>
                                    </a>
                                    {subNav.submenu && (
                                      <ul className="level1 submenu-t ui-menu-t">
                                        {subNav.submenu.map((subNavLvl2, k) => (
                                          <li
                                            key={k}
                                            className={`level2 nav-${i + 1}-${
                                              j + 1
                                            }-${
                                              k + 1
                                            } category-item ui-menu-item-t ${
                                              k === 0 ? 'first' : ''
                                            } ${
                                              k === subNav.submenu.length - 1
                                                ? 'last'
                                                : ''
                                            } ${
                                              subNavLvl2.submenu ? 'parent' : ''
                                            }`}
                                          >
                                            {subNavLvl2.submenu && (
                                              <i className="arr-menu-opener"></i>
                                            )}
                                            <a
                                              href={subNavLvl2.url}
                                              target={subNavLvl2.target}
                                              rel={subNavLvl2.rel}
                                              data-event="top_menu"
                                              data-event-category="Menu"
                                              data-event-action="Click Top Menu"
                                              data-event-label={`${mainNav.title} - ${subNav.title} - ${subNavLvl2.title}`}
                                              onClick={sendEventData}
                                            >
                                              <span>{subNavLvl2.title}</span>
                                            </a>
                                            {subNavLvl2.submenu && (
                                              <ul className="level2 submenu-t ui-menu-t">
                                                {subNavLvl2.submenu.map(
                                                  (subNavLvl3, l) => (
                                                    <li
                                                      key={l}
                                                      className={`level3 nav-${
                                                        i + 1
                                                      }-${j + 1}-${k + 1}-${
                                                        l + 1
                                                      } category-item ui-menu-item-t ${
                                                        l === 0 ? 'first' : ''
                                                      } ${
                                                        l ===
                                                        subNavLvl2.submenu
                                                          .length -
                                                          1
                                                          ? 'last'
                                                          : ''
                                                      }`}
                                                    >
                                                      <a
                                                        href={subNavLvl3.url}
                                                        target={
                                                          subNavLvl3.target
                                                        }
                                                        rel={subNavLvl3.rel}
                                                        data-event="top_menu"
                                                        data-event-category="Menu"
                                                        data-event-action="Click Top Menu"
                                                        data-event-label={`${mainNav.title} - ${subNav.title} - ${subNavLvl2.title} - ${subNavLvl3.title}`}
                                                        onClick={sendEventData}
                                                      >
                                                        <span>
                                                          {subNavLvl3.title}
                                                        </span>
                                                      </a>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <Language sendEventData={sendEventData} />
          <span
            className={`action nav-toggle icon-re mobile-toggle-menu ${
              showMobileMenu ? 'close-mob-menu' : ''
            }`}
            style={{ top: '68px' }}
            onClick={toggleMobileMenu}
          >
            <span>Toggle Nav</span>
          </span>
        </nav>
        {showMobileMenu && (
          <HeaderMobile
            topNav={topNav}
            showMobileMenu={showMobileMenu}
            toggleMobileMenu={toggleMobileMenu}
            sendEventData={sendEventData}
          />
        )}
      </div>
    </>
  );
}

export default Header;
