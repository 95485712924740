import { regex } from './regexs';
import { findStateAndCity } from 'utils/helper';

export const validationRules = (t) => ({
  regNo: {
    pattern: {
      value: /^[A-Za-z][A-Za-z0-9]{0,19}$/,
      message: t('v_f_reg_no_error_1')
    },
    trim: true,
    uppercase: true,
    required: t('v_f_reg_no_error_2'),
    maxLength: {
      value: 20,
      message: t('v_f_reg_no_error_3')
    },
    minLength: {
      value: 2,
      message: t('v_f_reg_no_error_4')
    }
  },
  postcode: {
    required: t('v_f_poscode_error_1'),
    maxLength: {
      value: 5,
      message: t('v_f_poscode_error_3')
    },
    validate: {
      validPostcode: (value) =>
        findStateAndCity(value) !== null || t('v_f_poscode_error_2')
    }
  },
  newIc: {
    required: t('v_f_ic_error_1'),
    pattern: {
      value: regex.IC_NUMBER,
      message: t('v_f_ic_error_2')
    },
    maxLength: {
      value: 12,
      message: t('v_f_ic_error_3')
    },
    minLength: {
      value: 12,
      message: t('v_f_ic_error_4')
    }
  },
  passport: {
    required: t('v_f_passport_error_1'),
    pattern: {
      value: regex.PASSPORT,
      message: t('v_f_passport_error_2')
    },
    maxLength: {
      value: 15,
      message: t('v_f_passport_error_3')
    },
    minLength: {
      value: 8,
      message: t('v_f_passport_error_4')
    }
  },
  fullName: {
    uppercase: true,
    required: t('enter_full_name')
  },
  mobileNumber: {
    required: t('v_f_mobile_error_1'),
    pattern: {
      value: regex.MOBILE_NUMBER,
      message: t('v_f_mobile_error_2')
    },
    minLength: {
      value: 9,
      message: t('v_f_mobile_error_3')
    },
    maxLength: {
      value: 10,
      message: t('v_f_mobile_error_4')
    },
    no0: true,
    trim: true
  },
  gender: {
    required: t('v_f_gender_error')
  },
  maritalStatus: {
    required: t('v_f_marital_error')
  },
  birthDate: {
    required: t('v_f_birthDate_error')
  },
  email: {
    required: t('email_required'),
    pattern: {
      value: regex.EMAIL,
      message: t('email_message')
    }
  },
  name: {
    required: t('add_driver_error1'),
    minLength: {
      value: 3,
      message: t('add_driver_error2')
    }
  }
});

export const errorMessages = {
  '001': 'error1',
  '008': 'error2',
  '000': 'error3',
  '077': 'error4',
  '003': 'error3',
  '009': 'error10',
  '031': 'error3',
  '033': 'error11',
  '034': 'error10',
  '035': 'error4'
};
