/** @jsxImportSource theme-ui */
import React from 'react';

import { style } from './CoverageOption.style';
import CoverageOptionLogic from './Logic/CoverageOption.logic';
import { Loading } from 'components';
import { ButtonUi } from 'components/UIComponent';
import { Translate } from 'hooks/Translation';

const InputField = ({
  bgColor,
  errorValue,
  id,
  isDisabled = false,
  label,
  onChange,
  placeholder,
  value
}) => (
  <div sx={{ backgroundColor: bgColor, ...style.fieldContainer }}>
    <label htmlFor={id} sx={style.inputLabel}>
      {label}
    </label>
    <div sx={style.inputContainer}>
      <span>RM</span>
      <input
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={isDisabled}
        sx={{
          border: 'none',
          outline: 'none',
          color:
            id === 'coverageValueInput'
              ? errorValue()
                ? 'red'
                : 'black'
              : '#1FC74E'
        }}
      />
    </div>
  </div>
);

function CoverageOption() {
  const { t } = Translate();
  const {
    confirmCoverage,
    errorValue,
    isLoading,
    isUpdate,
    onChangeWSC,
    price,
    sumInsured
  } = CoverageOptionLogic();

  return (
    <div sx={style.container}>
      <div sx={style.title}>{t('coverage_title')}</div>
      <div sx={style.description}>
        <div>{t('coverage_title_info')}</div>
        <div>{t('coverage_title_info_2')}</div>
      </div>
      <div sx={style.coverContainer}>
        <div sx={style.title}>{t('enter_preferred_coverage_value')}</div>
        <div sx={style.coverField}>
          <InputField
            label={t('coverage_value')}
            id="coverageValueInput"
            value={sumInsured}
            onChange={onChangeWSC}
            placeholder="500"
            errorValue={errorValue}
          />
          {isLoading ? (
            <Loading color={'blue'} />
          ) : (
            <InputField
              id="payableAmtInput"
              value={price}
              isDisabled={true}
              label={t('payable_amt')}
              bgColor={'#E7F6FF'}
              errorValue={errorValue}
            />
          )}
        </div>
      </div>
      {isUpdate ? (
        <ButtonUi
          label={errorValue() ? 'Try Again' : 'Update Price'}
          isDisabled={errorValue()}
          handleClick={confirmCoverage || null}
        />
      ) : null}
    </div>
  );
}

export default CoverageOption;
