import { useEffect, useState } from 'react';

import { IconError, IconSuccess } from 'assets/images';
import { isEmpty, isObjectEmpty } from 'utils/helper';

const useCheckComplete = (errors, fieldName) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [fieldBgColor, setFieldBgColor] = useState('');
  const [fieldIconStatus, setFieldIconStatus] = useState(null);

  const handleActive = (value) => {
    setFieldBgColor('success');
    setFieldIconStatus(IconSuccess);
    setActiveIndex(value);
  };

  const errorMessage = () => errors?.[fieldName]?.message;
  const errorStatus = () =>
    !isEmpty(errorMessage()) && !isObjectEmpty(errors) && !activeIndex;

  useEffect(() => {
    if (errorStatus()) {
      setFieldIconStatus(IconError);
    } else if (activeIndex) {
      setFieldBgColor('success');
    } else {
      setFieldBgColor('');
      setFieldIconStatus(null);
    }
  }, [errorStatus]);

  return {
    activeIndex,
    fieldBgColor,
    fieldIconStatus,
    handleActive,
    errorMessage,
    errorStatus
  };
};

export default useCheckComplete;
