import React, { lazy, Suspense } from 'react';

const ReactLoading = lazy(() => import('react-loading'));

export const Loading = ({ color, size }) => {
  const loadingColor = {
    blue: '#007bff',
    white: '#ffffff'
  };

  return (
    <Suspense fallback={<div></div>}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ReactLoading
          type={'spinningBubbles'}
          color={loadingColor?.[color] || loadingColor.blue}
          height={size || 20}
          width={size || 20}
        />
      </div>
    </Suspense>
  );
};

export default Loading;
