import { useCallback } from 'react';

import { useAppSelector } from './ReduxHook';
import clevertap from 'clevertap-web-sdk';
import IsMobile from 'hooks/IsMobile';
import { routeToEventMap } from 'utils/routeToEventMap';

const useCleverTapPush = (currentRoute) => {
  const eventConfig = routeToEventMap?.[currentRoute];

  const eventName = eventConfig?.eventName;
  const getData = eventConfig?.getData;
  const getProfileData = eventConfig?.getProfileData;

  const profileData = getProfileData ? useAppSelector(getProfileData) : null;
  const eventData = getData ? useAppSelector(getData) : null;

  const { type } = useAppSelector((state) => state.vhlType);
  const { isIpadView, isMobileView } = IsMobile();
  const isMobile = isIpadView || isMobileView;

  const pushData = useCallback(() => {
    setTimeout(() => {
      if (eventName && eventData) {
        clevertap.event.push(eventName, {
          Platform: isMobile ? 'mobile' : 'web',
          ...(eventData || {})
        });
      }
      if (profileData) {
        clevertap.profile.push(profileData);
      }
    }, 1000);
  }, [eventName, eventData, profileData, isMobile]);

  const pushClick = useCallback(
    (e, additionalData = {}, event) => {
      const target = e?.currentTarget;
      const clickData = {
        Platform: isMobile ? 'mobile' : 'web',
        'Vehicle type': type,
        Clickname: target?.getAttribute('data-event'),
        Pageview: eventName,
        ...additionalData
      };
      clevertap.event.push(event || 'click', clickData);
    },
    [type, eventName, isMobile]
  );

  return { pushData, pushClick };
};

export default useCleverTapPush;
