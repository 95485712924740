export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  information: {
    fontSize: '18px'
  },
  title: {
    fontFamily: 'medium'
  },
  radioContainer: {
    display: 'flex',
    gap: '12px'
  },
  radioCard: (active) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 15fr',
    gap: '12px',
    border: active ? '2px solid #5698FC' : '1px solid #DBDBDB',
    borderRadius: '5px',
    padding: '18px 6px',
    backgroundColor: active ? '#E7F6FF' : 'white'
  }),
  checkBox: {
    paddingTop: '5px'
  },
  label: {
    fontFamily: 'light',
    fontSize: '12px'
  },
  footerInfo: {
    fontSize: '16px',
    marginBottom: '5px'
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: '1fr 15fr'
  }
};
