import React from 'react';

import VehicleLoadingLogic from './Logic/VehicleLoading.logic';
import { style } from './VehicleLoading.style';
import { ProgressBar } from 'components/UIComponent';
import { Translate } from 'hooks/Translation';

const VehicleLoading = () => {
  const { countOfProgress, imageLeftPosition, message, vehicleImage } =
    VehicleLoadingLogic();
  const { t } = Translate();

  return (
    <div style={{ padding: '80px' }}>
      <div style={style.progressContainer}>
        <div style={style.carLoading({ imageLeftPosition })}>
          <img src={vehicleImage} alt={vehicleImage} />
        </div>
        <div style={style.progressBar}>
          <ProgressBar now={countOfProgress} variant="primary" />
        </div>
      </div>
      <div style={style.message}>{t(message)}</div>
    </div>
  );
};

export default VehicleLoading;
