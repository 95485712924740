import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';

import './inputStyles.scss';
import './inputDropdownText.scss';
import { ErrorMessage } from '@hookform/error-message';
import {
  IconChevronDown,
  IconChevronUp,
  IconError,
  IconSuccess
} from 'assets/images';

function InputDropdownText(state) {
  const [fieldDropdownIcon, setFieldDropdownIcon] = useState(IconChevronDown);
  const [fieldBgColor, setFieldBgColor] = useState('');
  const [fieldIconStatus, setFieldIconStatus] = useState(null);

  const {
    formState: { dirtyFields, errors },
    getValues,
    register,
    resetField,
    setValue
  } = useFormContext({ defaultValues: { [state.register.control]: '' } });

  const handleOnChangeDropdown = (e) => {
    const value = e.target.value;
    resetField(state.register.control);
    setFieldBgColor('');
    setFieldIconStatus(null);
    setFieldDropdownIcon(IconChevronDown);
    state.handleCallBack({ type_id: value, driver_id: state.fieldControlId });
  };

  const handleOnClickDropdown = () => {
    setFieldDropdownIcon(IconChevronUp);
  };

  useEffect(() => {
    if (state.formError || dirtyFields[state.register.control]) {
      if (
        !errors[state.register.control] &&
        Boolean(getValues(state.register.control))
      ) {
        setFieldBgColor('success');
        setFieldIconStatus(IconSuccess);
      } else if (
        Object.keys(errors).length > 0 &&
        errors[state.register.control]
      ) {
        setFieldBgColor('error');
        setFieldIconStatus(IconError);
      } else {
        setFieldBgColor('');
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group
      as={Col}
      lg={state.fieldLg}
      md={state.fieldMg}
      controlId={state.fieldControlId}
      className="form-element-container"
      style={{
        ...state.style
      }}
    >
      <InputGroup>
        {fieldIconStatus && (
          <img
            className="icon dropdown-icon"
            src={`${fieldIconStatus}`}
            alt="icon-error"
          />
        )}
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.floatingLabel}
          className={`input-text-select ${state.className}`}
        >
          <Form.Control
            as="select"
            onChange={handleOnChangeDropdown}
            disabled={state.optionsDisable}
            defaultValue={state.defaultOption}
            onClick={handleOnClickDropdown}
            className={`${fieldBgColor}`}
          >
            <option value="1">{state.firstOption}</option>
            <option value="2">Passport</option>
          </Form.Control>
          <img
            className="icon dropdown-icon"
            src={`${fieldDropdownIcon}`}
            alt="icon-dropdown"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.fieldPlaceholder}
          className="input-text-select insideLabel"
        >
          <Form.Control
            type={state.fieldType}
            placeholder={state.fieldPlaceholder}
            disabled={state.disabled}
            defaultValue={state.defaultValue}
            className={`${fieldBgColor} passport-nric-input`}
            maxLength={state.maxLength}
            onChange={(e) => {
              let value = e.target.value;
              if (state.register?.rules?.no0) {
                if (value.charAt(0) === '0') {
                  value = value.charAt(0).replace('0', '');
                }
              }
              setValue(state.register.control, value);
            }}
            onSelect={(e) => {
              let value = e.target.value;
              if (state.register?.rules?.no0) {
                if (value.charAt(0) === '0') {
                  value = value.charAt(0).replace('0', '');
                }
              }

              setValue(state.register.control, value);
            }}
            {...register(
              state.register.control,
              state.register.rules,
              state.register.maxLength
            )}
          />
        </FloatingLabel>
        {fieldIconStatus && (
          <img className="icon" src={`${fieldIconStatus}`} alt="icon-error" />
        )}
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state.register.control}
        render={({ messages }) => {
          return (
            <span className="error-label">
              {errors[state.register.control]?.message}
            </span>
          );
        }}
      />
    </Form.Group>
  );
}

export default InputDropdownText;
