import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import BaseReducer from './base';
import PremiumReducer from './response/premium';
import VixReducer from './response/vix';
import AdditionalDriverReducer from 'components/AdditionalDrivers/Logic/AdditionalDriver.reducer';
import AddOnsReducer from 'components/AddOns/Logic/AddOns.reducer';
import CheckoutReducer from 'components/Checkout/Logic/Checkout.reducer';
import CoverageOptionReducer from 'components/CoverageOption/Logic/CoverageOption.reducer';
import PlansReducer from 'components/Plans/Logic/Plans.reducer';
import VehicleFormReducer from 'pages/Home/SubComponents/VehicleForm/Logic/VehicleForm.reducer';
import VhlTypeReducer from 'pages/Home/SubComponents/VhlTypeContainer/Logic/VhlTypeContainer.reducer';
import { Services } from 'services/api/services';
import ModalReducer from 'store/modal';

const persistConfig = {
  key: 'insurance',
  storage
};

const rootReducer = combineReducers({
  additionalDriver: AdditionalDriverReducer,
  coverOption: CoverageOptionReducer,
  modal: ModalReducer,
  checkout: CheckoutReducer,
  addOns: AddOnsReducer,
  base: BaseReducer,
  premium: PremiumReducer,
  vix: VixReducer,
  vhlType: VhlTypeReducer,
  vehicleForm: VehicleFormReducer,
  plans: PlansReducer,
  [Services.reducerPath]: Services.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const setupStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'persist/PERSIST',
            'persist/REHYDRATE',
            'persist/PAUSE',
            'persist/PERSIST',
            'persist/PURGE',
            'persist/REGISTER'
          ]
        }
      }).concat(Services.middleware),
    preloadedState
  });

  const persistor = persistStore(store);
  return { store, persistor };
};

export default setupStore;
