import React from 'react';

import './CheckBoxContainer.scss';
import CheckBoxContainerLogic from './Logic/CheckBoxContainer.logic';
import { ArrowRight } from 'assets/images';

function CheckBoxContainer({
  addOnData,
  amount,
  boxWidth,
  handleChangeEvent,
  handleClick,
  info,
  options,
  title,
  val
}) {
  const { handleChange, isChecked } = CheckBoxContainerLogic();

  return (
    <>
      {title ? (
        <div
          className={`checkbox-container ${isChecked ? 'selected' : 'list'}`}
          style={{ width: boxWidth || '100%' }}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={val}
              id={val}
              onChange={() => handleChangeEvent(val)}
              checked={!!addOnData?.includes(val)}
            />
            <label className="form-check-label" htmlFor={val}>
              {title && title !== '' && (
                <>
                  <div className="header">
                    <div className={'mb-0 mt-1 title medium'}>{title}</div>
                    {amount && <div className="medium">{`RM ${amount}`}</div>}
                  </div>
                </>
              )}
              {info && info !== '' && <p className="info mt-2">{info}</p>}
              {options && (
                <div onClick={handleClick}>
                  <div className="option">
                    <div className="option-label">
                      {options.label}:{' '}
                      <span className="medium subBlueTitle">
                        {options.highlight}
                      </span>
                    </div>
                    <div className="option-callback">
                      <span className="subBlueTitle">Edit</span>{' '}
                      <img src={ArrowRight} alt="arrow-right" />{' '}
                    </div>
                  </div>
                </div>
              )}
            </label>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CheckBoxContainer;
