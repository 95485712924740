import { setCoverType } from 'components/Plans/Logic/Plans.reducer';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { setShowModal } from 'store/modal';

const PolicyTypeModalLogic = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.premium);
  const { isOldCar, selectSumInsured } = useAppSelector((state) => state.base);
  const { coverType } = useAppSelector((state) => state.plans);
  const { t } = Translate();

  const data = [
    {
      title: t('comprehensive'),
      isSelected: false,
      info: t('comprehensive_info'),
      value: '01'
    },
    isOldCar && {
      title: t('third_party'),
      isSelected: false,
      info: t('comprehensive_info'),
      value: '02'
    },
    {
      title: t('third_party_ft'),
      isSelected: false,
      info: t('third_party_ft_info'),
      value: '03'
    }
  ].filter(Boolean);

  const changeEvent = (index, value) => {
    dispatch(setCoverType(value));
    dispatch(setShowModal(false));
  };

  return {
    changeEvent,
    data: isOldCar ? data.slice().reverse() : data,
    isLoading,
    coverType,
    selectSumInsured
  };
};

export default PolicyTypeModalLogic;
