import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  coverType: undefined,
  sort: 'default',
  selectedPlan: undefined,
  planData: {
    '01': {
      sumInsured: undefined,
      reducedSumInsured: undefined
    },
    '02': {
      sumInsured: undefined,
      reducedSumInsured: undefined
    },
    '03': {
      sumInsured: undefined,
      reducedSumInsured: undefined
    }
  },
  selectedData: undefined,
  isLoading: undefined,
  lowestQuote: undefined
};

export const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setCoverType: (state, action) => {
      state.coverType = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setPlanData: (state, action) => {
      const { coverCode, data, selectSumInsured } = action.payload;
      state.planData = {
        ...state.planData,
        [coverCode]: {
          ...state.planData[coverCode],
          [selectSumInsured]: {
            ...state.planData[coverCode][selectSumInsured],
            ...data
          }
        }
      };
    },
    priceData: (state, action) => {
      const { addOnCode, compCode } = action.payload;
      const existingAddOns = state.priceData[compCode] || [];
      if (existingAddOns.includes(addOnCode)) {
        state.priceData[compCode] = existingAddOns.filter(
          (code) => code !== addOnCode
        );
      } else {
        state.priceData[compCode] = [...existingAddOns, addOnCode];
      }
    },
    setUpdateData: (state, action) => {
      const { coverType, key, selectedPlan, selectSumInsured, value } =
        action.payload;
      state.planData[coverType][selectSumInsured][selectedPlan][key] = value;
    },
    selectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setLowestQuote: (state, action) => {
      state.lowestQuote = action.payload;
    },
    resetVehicleState: () => initialState,
    resetSelectedPlan: (state) => {
      state.selectedPlan = initialState.selectedPlan;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const {
  resetSelectedPlan,
  resetVehicleState,
  selectedData,
  setCoverageWSCValue,
  setCoverType,
  setLoading,
  setLowestQuote,
  setPlanData,
  setPriceData,
  setSelectedCoverType,
  setSelectedPlan,
  setSort,
  setUpdateData
} = planSlice.actions;

export default planSlice.reducer;
