import { setSort } from 'components/Plans/Logic/Plans.reducer';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { setShowModal } from 'store/modal';

const PriceSortModalLogic = () => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.plans);
  const { t } = Translate();
  const chooseSort = (sort) => {
    dispatch(setSort(sort));
  };

  const highlight = (id) => {
    return plans.sort === id ? 'item-highlight' : 'not-highlight';
  };

  return {
    t,
    chooseSort,
    highlight,
    plans
  };
};

export default PriceSortModalLogic;
