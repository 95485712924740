import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useStepper } from './Stepper';
import { visitRoute } from 'store/base';

export const useRouteSequence = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { routes, visitedRoutes } = useSelector((state) => state.base);
  const { moveToFirstPage } = useStepper();

  useEffect(() => {
    const currentIndex = routes?.indexOf(location.pathname);

    const isValidRoute = currentIndex !== -1;

    const hasVisitedPreviousRoutes = routes
      .slice(0, currentIndex)
      .every((route, index) => visitedRoutes[index] === route);

    if (currentIndex > 0 && hasVisitedPreviousRoutes) {
      dispatch(visitRoute(location.pathname));
    }

    if (isValidRoute && currentIndex > 0 && !hasVisitedPreviousRoutes) {
      moveToFirstPage();
    } else {
      dispatch(visitRoute(location.pathname));
    }
  }, [location, routes, visitedRoutes, dispatch, moveToFirstPage]);
};

export default useRouteSequence;
