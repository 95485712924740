import useMobileSize from 'hooks/IsMobile';
import { useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { formatNumber } from 'utils/helper';

const PolicyDropdownLogic = () => {
  const { isMobileView } = useMobileSize();
  const plans = useAppSelector((state) => state.plans);
  const { t } = Translate();
  const { confirmVariation, sumInsuredOption, vix } = useAppSelector(
    (state) => state.vix
  );
  const { selectSumInsured } = useAppSelector((state) => state.base);
  const selectedPriceType = `${t('sort')}: ${t(plans.sort)}`;
  const sumInsuredLabel = `${t('sum_insured')}: ${t(
    formatNumber(sumInsuredOption[selectSumInsured])
  )}`;

  return {
    t,
    selectedPriceType,
    confirmVariation,
    vix,
    plans,
    sumInsuredLabel,
    isMobileView
  };
};

export default PolicyDropdownLogic;
