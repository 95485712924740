import { useEffect, useState } from 'react';

import useWindowSize from './WindowSize';
import { defaultEnum } from 'constants/enum';

export const useMobileSize = () => {
  const { width } = useWindowSize();
  const { ipadLayoutBreakpoint, mobileLayoutBreakpoint } = defaultEnum;
  const [isMobileView, setIsMobileView] = useState(
    width < mobileLayoutBreakpoint
  );
  const [isIpadView, setIsIpadView] = useState(width < ipadLayoutBreakpoint);

  useEffect(() => {
    setIsMobileView(width < mobileLayoutBreakpoint);
    setIsIpadView(width < ipadLayoutBreakpoint);
  }, [width, mobileLayoutBreakpoint, ipadLayoutBreakpoint]);

  return { isMobileView, isIpadView };
};

export default useMobileSize;
