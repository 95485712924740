import React from 'react';
import { Facebook, Instagram, Tiktok, Youtube } from 'react-bootstrap-icons';

import AppGallery from 'assets/images/appGallery.png';
import AppStore from 'assets/images/appStore.png';
import GooglePlay from 'assets/images/googlePlay.png';
import TwitterX from 'assets/images/icon/twitter-x.svg';

export const appLinks = [
  {
    href: 'https://apps.apple.com/my/app/pos-malaysia/id1325952009',
    imgSrc: AppStore,
    alt: 'app-store',
    platform: 'Apple App Store'
  },
  {
    href: 'https://play.google.com/store/apps/details?id=my.com.pos.posmobile.posmobileapps',
    imgSrc: GooglePlay,
    alt: 'google-play',
    platform: 'Google Play'
  },
  {
    href: 'https://appgallery.huawei.com/#/app/C101649649',
    imgSrc: AppGallery,
    alt: 'app-gallery',
    platform: 'Huawei App Gallery'
  }
];

export const socialLinks = [
  {
    href: 'https://www.instagram.com/pos4you/',
    component: <Instagram size={25} />,
    platform: 'Instagram'
  },
  {
    href: 'https://www.youtube.com/c/posmalaysiaberhadofficial',
    component: <Youtube size={25} />,
    platform: 'Youtube'
  },
  {
    href: 'https://twitter.com/i/flow/login?redirect_after_login=%2Fposmalaysia',
    component: (
      <img src={TwitterX} alt="Twitter X" style={{ height: 24, width: 24 }} />
    ),
    platform: 'Twitter'
  },
  {
    href: 'https://www.facebook.com/posmalaysia',
    component: <Facebook size={25} />,
    platform: 'Facebook'
  },
  {
    href: 'https://www.tiktok.com/@posmalaysia',
    component: <Tiktok size={25} />,
    platform: 'TikTok'
  }
];
