export const Fields = {
  ID_TYPE: 'type',
  CAR_NUMBER: 'regNo',
  ID_NUMBER: 'newIc',
  DOB: 'birthDate',
  AGE: 'age',
  GENDER: 'gender',
  MARITAL_STATUS: 'maritalStatus',
  MOBILE_NUMBER: 'mobileNumber',
  PASSPORT_NUMBER: 'passportNo',
  POSTCODE: 'postcode',
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  STATE: 'state',
  CITY: 'city',
  ADDRESS: 'address'
};

export const Options = {
  IC: 'IC',
  PASSPORT: 'PASSPORT',
  ARMY: 'ARMY/POLICE/OTHERS',
  COMPANY: 'COMPANY REGISTRATION NUMBER'
};
