import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vix: undefined,
  confirmVariation: undefined,
  sumInsuredOption: {
    sumInsured: undefined,
    reducedSumInsured: undefined
  }
};

export const vixSlice = createSlice({
  name: 'vix',
  initialState,
  reducers: {
    setVehicleData: (state, action) => {
      state.vix = action.payload;
    },
    setVariation: (state, action) => {
      state.confirmVariation = action.payload;
    },
    setSumInsuredOption: (state, action) => {
      state.sumInsuredOption = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const {
  setSumInsured,
  setSumInsuredOption,
  setVariation,
  setVehicleData
} = vixSlice.actions;

export default vixSlice.reducer;
