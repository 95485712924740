import React from 'react';

import 'pages/Home/SubComponents/AdvantageContainer/AdvantageContainer.scss';
import { GirlWithPhone } from 'assets/images';
import { CarTypeContainer } from 'components';
import { Translate } from 'hooks/Translation';

function RoadtaxContainer() {
  const { t } = Translate();

  return (
    <>
      <div className="advantage-container d-flex flex-column flex-lg-row flex-wrap mt-5">
        <div className="advantage col-12 col-sm-12 col-lg-6 col-xl-6 p-sm-0 px-sm-4 pt-sm-3 p-4">
          <div>
            <h1 className="adv-title-1 bold">
              {t('rtxTitle_1')}{' '}
              <span className="subBlueTitle">{t('rtxTitle_2')} </span>{' '}
              {t('rtxTitle_3')}
            </h1>
            <p className="adv-title-2">{t('rtxInfo')}</p>
          </div>
          <div className="adv-list d-flex flex-wrap mb-3">
            <CarTypeContainer
              color={'blue'}
              type={'car'}
              infoButton={true}
              url={'https://roadtax.pos.com.my/'}
            />
            <CarTypeContainer
              color={'blue'}
              type={'motorcycle'}
              infoButton={true}
              url={'https://roadtax.pos.com.my/'}
            />
          </div>
        </div>
        <div className="rtax-img col-sm-12 col-lg-6 col-xl-6">
          <img src={GirlWithPhone} alt="road-tax" />
        </div>
      </div>
    </>
  );
}

export default RoadtaxContainer;
