/** @jsxImportSource theme-ui */
import React from 'react';

import PriceSortModalLogic from './Logic/PriceSortModal.logic';
import { style } from './PriceSortModal.style';
import { ButtonUi, RadioCheck } from 'components/UIComponent';

function PriceSortOption({ chooseSort, label, plans, t, value }) {
  return (
    <div
      onClick={() => chooseSort(value)}
      style={style.sortCard(plans.sort === value)}
    >
      <div sx={style.radioCheck}>
        <RadioCheck
          active={plans.sort === value}
          handleClick={chooseSort}
          id={value}
          value={value}
        />
        <div sx={style.label}>{t(label)}</div>
      </div>
    </div>
  );
}

function PriceSortModal() {
  const { chooseSort, plans, t } = PriceSortModalLogic();

  const sortOptions = [
    { value: 'default', label: 'default' },
    { value: 'price_up', label: 'price_up' },
    { value: 'price_down', label: 'price_down' }
  ];

  return (
    <div sx={style.container}>
      <div sx={style.title}>Sort</div>
      {sortOptions.map((option) => (
        <PriceSortOption
          key={option.value}
          value={option.value}
          label={option.label}
          plans={plans}
          chooseSort={chooseSort}
          t={t}
        />
      ))}
      <ButtonUi label={'confirm'} />
    </div>
  );
}

export default PriceSortModal;
