import { useState } from 'react';

const CheckBoxContainerLogic = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (index, event) => {
    event.stopPropagation();

    setIsChecked(!isChecked);
  };
  return {
    isChecked,
    handleChange
  };
};

export default CheckBoxContainerLogic;
