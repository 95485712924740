import {
  addOnsPageview,
  customerConfirmationPageview,
  paymentSuccessPageview,
  profilePageview,
  verificationPageview
} from 'services/decorator/pushData/cleverTap';

export const routeToEventMap = {
  '/user-verification': {
    eventName: 'Verification_pageview',
    getData: verificationPageview,
    getProfileData: profilePageview
  },
  '/addons': {
    eventName: 'Addons_pageview',
    getData: addOnsPageview,
    getProfileData: profilePageview
  },
  '/customer-details': {
    eventName: 'CustomerDetails_pageview',
    getData: addOnsPageview,
    getProfileData: profilePageview
  },
  '/checkout': {
    eventName: 'CustomerConfirmation_pageview',
    getData: customerConfirmationPageview,
    getProfileData: profilePageview
  },
  '/payment-status': {
    eventName: 'PaymentSuccess_pageview',
    getData: paymentSuccessPageview,
    getProfileData: profilePageview
  }
};
