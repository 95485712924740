export const style = {
  progressContainer: {
    paddingTop: '20px'
  },
  carLoading: ({ imageLeftPosition }) => ({
    position: 'absolute',
    left: imageLeftPosition,
    top: 70,
    zIndex: 1,
    transition: 'left 0.7s linear'
  }),
  progressBar: {
    position: 'relative',
    textAlign: 'center'
  },
  message: {
    textAlign: 'center',
    fontSize: '22px'
  }
};
