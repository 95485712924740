import { createSlice } from '@reduxjs/toolkit';

import { Fields } from '../Constant';
import { regex } from 'constants/regexs';
import { getAgeFromDOB, getICYear } from 'utils/helper';

export const initialState = {
  [Fields.CAR_NUMBER]: undefined,
  [Fields.ID_TYPE]: undefined,
  [Fields.ID_NUMBER]: undefined,
  [Fields.DOB]: undefined,
  [Fields.AGE]: undefined,
  [Fields.GENDER]: undefined,
  [Fields.MARITAL_STATUS]: undefined,
  [Fields.MOBILE_NUMBER]: undefined,
  [Fields.PASSPORT_NUMBER]: undefined,
  [Fields.POSTCODE]: undefined,
  [Fields.FULL_NAME]: undefined,
  [Fields.EMAIL]: undefined,
  [Fields.STATE]: undefined,
  [Fields.CITY]: undefined,
  [Fields.ADDRESS]: undefined
};

export const vehicleFormSlice = createSlice({
  name: 'vehicleForm',
  initialState,
  reducers: {
    setFieldValue: (state, action) => {
      state[Fields.CAR_NUMBER] = action.payload.regNo;
      state[Fields.MOBILE_NUMBER] = action.payload.mobileNumber;
      state[Fields.POSTCODE] = action.payload.postcode;
      state[Fields.FULL_NAME] = action.payload.fullName;
      state[Fields.STATE] = action.payload?.state;
      state[Fields.CITY] = action.payload?.city;
      state[Fields.ADDRESS] = action.payload?.address;
      state[Fields.EMAIL] = action.payload.email;
      state[Fields.MARITAL_STATUS] = action.payload.maritalStatus;
      if (action.payload?.newIc) {
        const { data, status } = extractData(action.payload.newIc);
        if (status === 'SUCCESS') {
          state[Fields.ID_NUMBER] = data.ICNumber;
          state[Fields.DOB] = `${data.Year}-${data.Month}-${data.Day}`;
          state[Fields.AGE] = data.Age;
          state[Fields.GENDER] = data.Gender;
        } else {
          state[Fields.ID_NUMBER] = data.ICNumber;
        }
      } else {
        state[Fields.PASSPORT_NUMBER] =
          action.payload?.passport || action.payload.passportNo;
        state[Fields.DOB] = action.payload.birthDate;
        state[Fields.GENDER] = action.payload.gender;
        state[Fields.ID_NUMBER] = undefined;
      }
    },
    setMaritalStatus: (state, action) => {
      state[Fields.MARITAL_STATUS] = action.payload;
    },
    resetCarState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const { resetCarState, setFieldValue, setMaritalStatus } =
  vehicleFormSlice.actions;

export default vehicleFormSlice.reducer;

export const regexICNumber = regex.IC_NUMBER;

const extractData = (icNumber) => {
  const icNumberValue =
    typeof icNumber === 'string' ? icNumber : icNumber.value;
  const indexes = {
    0: 'ICNumber',
    1: 'Year',
    2: 'Month',
    3: 'Day',
    4: 'PB',
    5: 'Number',
    6: 'Gender'
  };

  const result = {};
  const regex = regexICNumber;

  if (regex.test(icNumberValue)) {
    let foundGroupInRegex = regex.exec(icNumberValue);
    while ((foundGroupInRegex = regex.exec(icNumberValue)) !== null) {
      if (foundGroupInRegex.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      foundGroupInRegex.forEach((match, groupIndex) => {
        result[indexes[groupIndex]] = match;
      });
    }
    if (Object.keys(result).length === 7) {
      result.Gender = parseInt(result.Gender) % 2 === 0 ? 'F' : 'M';
      result.Year = getICYear(result.Year);
      result.Age = getAgeFromDOB(
        new Date(`${result.Month}/${result.Day}/${result.Year}`)
      );
      return { status: 'SUCCESS', data: result };
    }
  } else {
    return {
      status: 'FAIL',
      data: {
        message: 'The Ic Number is not valid',
        ICNumber: icNumberValue
      }
    };
  }
};
