import React from 'react';

import MainContainerLogic from './Logic/MainContainer.logic';
import { ModalContainer } from 'components/UIComponent';

const MainContainer = ({
  children,
  handleClose,
  isLoading,
  modalProps,
  refId
}) => {
  const { childrenRender, close, isUpdate, show } = MainContainerLogic({
    isLoading,
    ...modalProps,
    handleClose,
    refId
  });

  return (
    <>
      <div>{children}</div>
      <ModalContainer show={show} handleHide={isUpdate ? null : close}>
        {childrenRender}
      </ModalContainer>
    </>
  );
};

export default MainContainer;
