import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

const useDataLayerPush = () => {
  const dataLayerPush = useCallback((eventData) => {
    TagManager.dataLayer({ dataLayer: eventData });
  }, []);

  const sendEventData = useCallback(
    (e, data = {}) => {
      const target = e?.currentTarget;
      const eventData = {
        event: target?.getAttribute('data-event') || data.event,
        event_category:
          target?.getAttribute('data-event-category') || data.event_category,
        event_action:
          target?.getAttribute('data-event-action') || data.event_action,
        event_label:
          target?.getAttribute('data-event-label') || data.event_label,
        ...data
      };

      dataLayerPush(eventData);
    },
    [dataLayerPush]
  );

  const sendPageData = useCallback(
    (pageData) => {
      dataLayerPush(pageData);
    },
    [dataLayerPush]
  );

  return { sendEventData, sendPageData };
};

export default useDataLayerPush;
