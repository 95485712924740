import React, { forwardRef } from 'react';

const InputMobile = forwardRef(({ children, classes, onClick }, ref) => {
  return (
    <div className={classes} onClick={onClick} ref={ref}>
      {children}
    </div>
  );
});

InputMobile.displayName = 'InputMobile';

export default InputMobile;
