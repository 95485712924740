import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { setDriversData, setSelectedDrivers } from './AdditionalDriver.reducer';
import {
  setAdditionalDrivers,
  setIsUpdate
} from 'components/CoverageOption/Logic/CoverageOption.reducer';
import { validationRules } from 'constants/validationRules';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { useUpdateDriverAddOnMutation } from 'services/api/services';
import { premiumRequest } from 'services/decorator/request';
import { setShowModal } from 'store/modal';

export const AdditionalDriversLogic = () => {
  const dispatch = useAppDispatch();
  const { t } = Translate();
  const rules = validationRules(t);
  const [updateDriverAddOn, { isLoading }] = useUpdateDriverAddOnMutation();
  const premium = useAppSelector(premiumRequest);
  const [formError, setFormError] = useState(null);
  const [isPassport, setIsPassport] = useState(false);
  const { driversData, selectedDrivers, selectSumInsured } = useAppSelector(
    (state) => ({
      selectSumInsured: state.base.selectSumInsured,
      driversData: state.additionalDriver.driversData,
      selectedDrivers: state.additionalDriver.selectedDrivers
    })
  );
  const [drivers, setDrivers] = useState([selectedDrivers?.[0] || { id: 0 }]);
  const [driverCount, setDriverCount] = useState(0);
  const useFormMethod = useForm({
    mode: 'all'
  });
  const {
    formState: { errors },
    register
  } = useFormMethod;

  useEffect(() => {
    if (selectedDrivers?.length > 0) {
      setDrivers(selectedDrivers.map((driver, index) => ({ id: index })));
    }
  }, [selectedDrivers]);

  const onSubmit = async (data) => {
    dispatch(setDriversData(data));
    dispatch(setSelectedDrivers(drivers));
    dispatch(setShowModal(true));
    dispatch(setIsUpdate(true));
    const driversFormData = formatDriverData(data);
    dispatch(setAdditionalDrivers(driversFormData));
    await updateDriverAddOn({
      ...premium,
      selectSumInsured,
      addBenData: {
        item: [
          {
            benCode: 'AND',
            benDesc: 'NAMED DRIVER(S)'
          }
        ]
      },
      addDrvData: {
        item: [...premium?.addDrvData?.item, ...driversFormData]
      }
    });
    dispatch(setShowModal(false));
    dispatch(setIsUpdate(false));
  };

  const onError = (error) => {
    setFormError(error);
  };

  const addDriver = () => {
    setDriverCount((prev) => prev + 1);
    if (drivers?.length < 5) {
      drivers.push({ id: driverCount + 1 });
    }
  };

  const removeDriver = (index) => {
    setDrivers(drivers.filter((driver) => driver.id !== index));
  };

  const callBack = ({ type_id: typeId }) => setIsPassport(typeId === '2');

  const formatDriverData = (formData) => {
    return drivers.map((driver) => {
      return {
        name: formData[`name${driver.id}`],
        icNumber: formData[`icNumber${driver.id}`]
      };
    });
  };

  const formatDriverDefaultData = (driverData) => {
    const data = selectedDrivers?.reduce((acc, driver) => {
      return {
        ...acc,
        [`name${driver.id}`]: driverData[`name${driver.id}`],
        [`icNumber${driver.id}`]: driverData[`icNumber${driver.id}`]
      };
    }, {});
    return data;
  };

  return {
    addDriver,
    removeDriver,
    onSubmit,
    onError,
    useFormMethod,
    register,
    errors,
    rules,
    t,
    formError,
    callBack,
    isPassport,
    drivers,
    isLoading,
    driversData: formatDriverDefaultData(driversData)
  };
};
