import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allowedEnv: process.env.REACT_APP_ENV === 'production',
  routes: [
    '/',
    '/user-verification',
    '/plans',
    '/addons',
    '/customer-details',
    '/checkout',
    '/payment-status'
  ],
  queryStrings: {},
  language: 'en',
  visitedRoutes: ['/'],
  steps: 0,
  isOldCar: undefined,
  referralCode: undefined,
  selectSumInsured: 'sumInsured',
  sumInsured: undefined,
  stepperRoutes: ['/plans', '/addons', '/customer-details', '/checkout'],
  isLoading: undefined
};

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    visitRoute: (state, action) => {
      if (!state.visitedRoutes?.includes(action.payload)) {
        state.visitedRoutes = [...state.visitedRoutes, action.payload];
      }
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setOldCar: (state, action) => {
      state.isOldCar = action.payload;
    },
    setReferralCode: (state, action) => {
      state.referralCode = action.payload;
    },
    setSelectedSumInsured: (state, action) => {
      state.selectSumInsured = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSumInsured: (state, action) => {
      state.sumInsured = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('reset', () => initialState);
  }
});

export const {
  setIsLoading,
  setLanguage,
  setLoading,
  setOldCar,
  setReferralCode,
  setSelectedSumInsured,
  setSteps,
  setSumInsured,
  visitRoute
} = baseSlice.actions;

export default baseSlice.reducer;
