export const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px'
  },
  button: (mobile) => ({
    width: mobile ? '120px' : '164px',
    height: '50px',
    boxShadow: '2px 6px 10px 0px rgba(86, 152, 252, 0.35)',
    backgroundColor: '#5698FC',
    borderRadius: '5px',
    color: 'white'
  })
};
