import { useEffect, useState } from 'react';

import { setAddOnData, setSelectAll } from './AddOns.reducer';
import { benefitCodeToInfo, benefitCodeToTitle } from 'constants/label';
import useCleverTapPush from 'hooks/CleverTapData';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';
import { setModalProps, setShowModal } from 'store/modal';

const AddOnsLogic = () => {
  const dispatch = useAppDispatch();
  const { coverType, planData, selectedPlan } = useAppSelector(
    (state) => state.plans
  );
  const { addOnData } = useAppSelector((state) => state.addOns);
  const { selectSumInsured } = useAppSelector((state) => state.base);
  const { modal, showModal } = useAppSelector((state) => state.modal);
  const [toggle, setToggle] = useState(false);
  const { currentRoute, moveNext } = useStepper();
  const { pushData } = useCleverTapPush(currentRoute);

  const { t } = Translate();

  useEffect(() => {
    pushData();
  }, []);

  const getAddOnsList = () => {
    return planData?.[coverType]?.[selectSumInsured]?.[selectedPlan];
  };

  const companyCode = selectedPlan;

  const getOptions = (benCode, sumInsured) => {
    if (benCode === 'WSC') {
      return {
        label: `${t('coverage')}`,
        highlight: `RM ${sumInsured}`
      };
    }
    if (benCode === 'AND') {
      return { label: `${t('driver')}`, highlight: 'All' };
    }
  };

  const addOnEvent = (addOnCode, e) => {
    dispatch(setAddOnData({ compCode: selectedPlan, addOnCode }));
  };

  const selectAll = () => {
    setToggle(!toggle);
    if (toggle) {
      dispatch(setSelectAll({ addOns: [], compCode: selectedPlan }));
    } else {
      const addOns = getAddOnsList()
        ?.map(({ benCode }) => benCode)
        .filter((benCode) => benefitCodeToInfo?.[benCode]);
      dispatch(setSelectAll({ addOns, compCode: selectedPlan }));
    }
  };

  const updateAddOn = (benCode) => {
    if (benCode === 'WSC') {
      dispatch(
        setModalProps({
          type: 'updateCoverage'
        })
      );
      dispatch(setShowModal(true));
    }
    if (benCode === 'AND') {
      dispatch(
        setModalProps({
          type: 'additionalDrivers'
        })
      );
      dispatch(setShowModal(true));
    }
  };

  const closeModal = () => {
    dispatch(setShowModal(false));
  };

  return {
    t,
    benefitCodeToTitle,
    benefitCodeToInfo,
    getAddOnsList,
    companyCode,
    getOptions,
    addOnEvent,
    addOnData: addOnData[companyCode],
    selectAll,
    toggle,
    moveNext,
    modalProps: modal,
    showModal,
    updateAddOn,
    closeModal
  };
};

export default AddOnsLogic;
