import React from 'react';

import { style } from './RadioCheck.style';
import { Checked, UnChecked } from 'assets/images';

const RadioCheck = ({ active, handleClick, id, value }) => {
  return (
    <div>
      <input
        type="radio"
        name={id}
        value={value}
        onChange={() => handleClick(id)}
        checked={active}
        style={{ display: 'none' }}
      />
      <img src={active ? Checked : UnChecked} sx={style.checkBox} />
    </div>
  );
};

export default RadioCheck;
