import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import './faq.scss';
import { faq } from 'constants/faq';
import DataLayer from 'hooks/DataLayerPush';
import { Translate } from 'hooks/Translation';

function Faq() {
  const { t } = Translate();
  return (
    <div className="faqContainer" id="faq">
      <div className="logo-faq medium">
        <div className="title d-flex text-left">{t('faq_title')}</div>
      </div>
      <div className="ExpandCollapse d-flex">
        <ExpandCollapse />
      </div>
    </div>
  );
}

export default Faq;

function ExpandCollapse() {
  const { t } = Translate();
  const { sendEventData } = DataLayer();
  const handleExpandEvent = (e) => {
    const data = {
      event: 'expand_faq',
      event_category: 'Home',
      event_action: 'Expand FAQ',
      event_label: e.target.textContent
    };
    sendEventData(e, data);
  };

  return (
    <>
      {faq?.length ? (
        <Accordion defaultActiveKey="0" alwaysOpen>
          {faq.map((data, i) => (
            <Accordion.Item className="item" key={i} eventKey={i}>
              <Accordion.Header
                className="header medium"
                onClick={handleExpandEvent}
              >
                {t(data.title)}
              </Accordion.Header>
              <Accordion.Body className="body">
                {typeof data.description === 'function'
                  ? data.description(t)
                  : t(data.description)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <div>{t('no_data_found')}</div>
      )}
    </>
  );
}
