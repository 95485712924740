import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { setSelectedSumInsured, setSumInsured } from 'store/base';
import { setShowModal } from 'store/modal';

const SumInsuredOptionLogic = () => {
  const dispatch = useAppDispatch();
  const { confirmVariation, sumInsuredOption } = useAppSelector(
    (state) => state.vix
  );
  const { selectSumInsured } = useAppSelector((state) => state.base);

  useEffect(() => {
    dispatch(setSumInsured(confirmVariation?.sumInsured));
  }, []);

  const handleClick = (id, value) => {
    dispatch(setSelectedSumInsured(id));
    dispatch(setSumInsured(value));
  };

  const close = () => {
    dispatch(setShowModal(false));
  };

  return {
    handleClick,
    sumInsuredOption,
    selectSumInsured,
    close
  };
};

export default SumInsuredOptionLogic;
