/** @jsxImportSource theme-ui */
import React from 'react';
import { FormProvider } from 'react-hook-form';

import { style } from './AdditionalDriver.style';
import { AdditionalDriversLogic } from './Logic/AdditionalDrivers.logic';
import { InputDropdownText, InputText, Loading } from 'components';
import { Button, ButtonUi, Form } from 'components/UIComponent';

export const AdditionalDriverForm = () => {
  const {
    addDriver,
    callBack,
    drivers,
    driversData,
    formError,
    isLoading,
    isPassport,
    onError,
    onSubmit,
    removeDriver,
    rules,
    t,
    useFormMethod
  } = AdditionalDriversLogic();

  return (
    <div sx={style.container}>
      <div sx={style.header}>
        <div sx={style.title}>{t('additional_driver')}</div>
        <div sx={style.description}>{t('and')}</div>
      </div>
      <FormProvider {...useFormMethod}>
        <Form
          id="additionalDriverForm"
          onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}
        >
          <div sx={style.formContainer}>
            {drivers?.length < 3 && (
              <div className="d-grid gap-2">
                <Button onClick={addDriver} variant={'outline-primary'}>
                  + {t('Add Driver')}
                </Button>
              </div>
            )}
            {drivers?.map((field, index) => (
              <div key={field.id} sx={style.fieldsContainer}>
                <div sx={style.addDriverTitle}>
                  {`${t('Additional Driver')} ${index + 1}`}
                  {drivers?.length > 1 && (
                    <div
                      onClick={() => removeDriver(field.id)}
                      sx={style.removeDriver}
                    >
                      {t('Remove Driver')}
                    </div>
                  )}
                </div>
                <InputText
                  fieldLg="12"
                  fieldControlId={field.id}
                  floatingControlId={'floatingInput'}
                  floatingLabel={t('add_driver_name')}
                  fieldType="text"
                  detectEmpty={() => null}
                  formError={formError}
                  register={{
                    control: `name${field.id}`,
                    rules: rules.name
                  }}
                  defaultValue={driversData?.[`name${field.id}`]}
                />
                <InputDropdownText
                  fieldLg="12"
                  fieldControlId={field.id}
                  firstOption="NRIC"
                  floatingControlId={'floatingInput'}
                  floatingLabel={t('v_f_ic_type')}
                  fieldType="text"
                  fieldPlaceholder={t('v_f_ic_num')}
                  formError={formError}
                  handleCallBack={callBack}
                  maxLength={isPassport ? '15' : '12'}
                  isPassport={isPassport}
                  register={
                    !isPassport
                      ? {
                          control: `icNumber${field.id}`,
                          rules: rules.newIc
                        }
                      : {
                          control: `drvPassport${field.id}`,
                          rules: rules.passport
                        }
                  }
                  style={{
                    width: '100%'
                  }}
                  defaultValue={driversData?.[`icNumber${field.id}`]}
                />
              </div>
            ))}
          </div>
        </Form>
      </FormProvider>
      <ButtonUi
        type={'submit'}
        isDisabled={isLoading}
        form={'additionalDriverForm'}
        handleClick={() => null}
      >
        {isLoading ? <Loading color={'white'} /> : t('confirm')}
      </ButtonUi>
    </div>
  );
};

export default AdditionalDriverForm;
