import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useLocalStorage from './LocalStorage';
import { useAppSelector } from './ReduxHook';

const useSaveQueryParams = () => {
  const navigate = useNavigate();
  const { queryStrings } = useAppSelector((state) => state.base);

  const {
    deleteValueFromLocalStorage,
    getValueFromLocalStorage,
    saveValueToLocalStorage
  } = useLocalStorage();

  const saveReqIdToLocalStorage = (reqId) => {
    const latestQueryStrings = getValueFromLocalStorage('queryStrings') || {};

    const newQueryStrings = {
      ...latestQueryStrings,
      reqId
    };

    saveValueToLocalStorage('queryStrings', newQueryStrings);
  };

  const saveLangToLocalStorage = (value) => {
    const latestQueryStrings = getValueFromLocalStorage('queryStrings') || {};

    const newQueryStrings = {
      ...latestQueryStrings,
      lang: value
    };

    saveValueToLocalStorage('queryStrings', newQueryStrings);
  };

  const deleteQuotationNoFromLocalStorage = () => {
    saveValueToLocalStorage('queryStrings', {
      applicationId: queryStrings.applicationId
    });
  };

  const getReqIdQueryStrings = () => {
    const applicationId = new URL(document.location).searchParams.get('reqId');
    return applicationId;
  };

  const getLangFromQueryStrings = () => {
    const lang = new URL(document.location).searchParams.get('lang');
    return lang;
  };

  const getAllQueryStrings = () => {
    const urlParams = new URL(document.location).searchParams;
    const params = {};

    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }

    return params;
  };

  const getReqIdFromLocalStorage = () => {
    const queryParamsFromLocalStorage =
      getValueFromLocalStorage('queryStrings');
    return queryParamsFromLocalStorage?.reqId;
  };

  const getLangFromLocalStorage = () => {
    const queryParamsFromLocalStorage =
      getValueFromLocalStorage('queryStrings');
    return queryParamsFromLocalStorage?.lang;
  };

  return {
    getAllQueryStrings,
    getReqIdFromLocalStorage,
    getReqIdQueryStrings,
    queryParamsFromLocalStorage: getValueFromLocalStorage('queryStrings'),
    saveReqIdToLocalStorage,
    saveLangToLocalStorage,
    getLangFromLocalStorage,
    getLangFromQueryStrings,
    deleteQuotationNoFromLocalStorage
  };
};

export default useSaveQueryParams;
